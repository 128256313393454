
export const SAMPLE_GLOW_01 = [
    {
        "id": "1",
        "audience": "Students",
        "key_points": [
            "Interactive coding challenges",
            "Progress tracking and analytics",
            "Peer collaboration features",
            "Gamified learning experience",
            "Access to expert tutorials"
        ],
        "key_points_mapping": "Problem Analysis",
        "main_message": "Unlock your coding potential with our interactive mobile app.",
        "main_message_mapping": "Main Message",
        "desired_outcome": "Engage more students in learning to code effectively.",
        "desired_outcome_mapping": "Engagement Strategy",
        "visual_description": "Show a student using the app on a smartphone, navigating through coding challenges with enthusiasm, and sharing progress with friends.",
        "target_duration_in_sec": "120"
    },
    {
        "id": "2",
        "audience": "Parents",
        "key_points": [
            "Monitored learning environment",
            "Skill development tracking",
            "Encouragement of logical thinking",
            "Safe community interaction",
            "Enhancing educational outcomes"
        ],
        "key_points_mapping": "Parenting Insights",
        "main_message": "Support your child's coding journey with our safe and engaging app.",
        "main_message_mapping": "Main Message",
        "desired_outcome": "Encourage parents to adopt the app for their children’s coding education.",
        "desired_outcome_mapping": "Adoption Strategy",
        "visual_description": "Depict a parent observing their child happily learning coding on the app, with visuals of progress reports and skill achievements.",
        "target_duration_in_sec": "120"
    }
]

export const SAMPLE_GLOW_02 = {
    "status": true,
    "message": "Glow_02 process completed successfully",
    "status_code": 200,
    "data": {
        "glow_02_response": {
            "1": {
                "video_id": "1_students",
                "audience": "Students",
                "content_reference": 0,
                "video_base": {
                    "style": "Dynamic",
                    "audio_base": {
                        "timbre": "Electronic beats and soft synths",
                        "tempo": 120,
                        "core_rhythm": "4/4",
                        "harmonic_base": "C Major"
                    },
                    "color_scheme": "Blue and White with Yellow accents",
                    "target_duration_in_sec": "120"
                },
                "scenes": [
                    {
                        "sc_id": "1",
                        "sc_title": "Introduction to Coding",
                        "sc_type": "Human-focused",
                        "sc_length_seconds": "6",
                        "sc_purpose": "Introduce coding concepts",
                        "sc_emotion": "subtle",
                        "sc_audio": {
                            "voiceover": {
                                "vo_text": "Let's dive into the exciting world of coding!",
                                "vo_duration": "5.5"
                            },
                            "sound_design": {
                                "style": "uplifting",
                                "intensity": 5
                            }
                        },
                        "sc_visuals_basics": "Natural expressions of students engaging with the app",
                        "sc_visuals_extensive": "A close-up shot of a student smiling while looking at a coding app on a tablet.",
                        "sc_success_metric": "Audience interest in coding concepts",
                        "sc_count": 0
                    },
                    {
                        "sc_id": "2",
                        "sc_title": "Interactive Challenges",
                        "sc_type": "Product/Service",
                        "sc_length_seconds": "8",
                        "sc_purpose": "Showcase interactive coding challenges",
                        "sc_emotion": "controlled",
                        "sc_audio": {
                            "voiceover": {
                                "vo_text": "Engage with fun coding challenges designed for you.",
                                "vo_duration": "7.5"
                            },
                            "sound_design": {
                                "style": "energetic",
                                "intensity": 6
                            }
                        },
                        "sc_visuals_basics": "Students tackling challenges in pairs",
                        "sc_visuals_extensive": "A medium shot showing two students collaborating on a coding challenge, with screens reflecting their activity.",
                        "sc_success_metric": "Increased participation in challenges",
                        "sc_count": 0
                    },
                    {
                        "sc_id": "3",
                        "sc_title": "Real-Time Feedback",
                        "sc_type": "Data/Information",
                        "sc_length_seconds": "7",
                        "sc_purpose": "Highlight real-time feedback features",
                        "sc_emotion": "subtle",
                        "sc_audio": {
                            "voiceover": {
                                "vo_text": "Receive instant feedback to improve your skills.",
                                "vo_duration": "6.5"
                            },
                            "sound_design": {
                                "style": "informative",
                                "intensity": 5
                            }
                        },
                        "sc_visuals_basics": "Screens showing feedback notifications",
                        "sc_visuals_extensive": "A close-up of a smartphone screen displaying feedback after completing a task.",
                        "sc_success_metric": "Understanding of feedback importance",
                        "sc_count": 0
                    },
                    {
                        "sc_id": "4",
                        "sc_title": "Community Support",
                        "sc_type": "Human-focused",
                        "sc_length_seconds": "6",
                        "sc_purpose": "Emphasize community support",
                        "sc_emotion": "controlled",
                        "sc_audio": {
                            "voiceover": {
                                "vo_text": "Join a community that supports your learning journey.",
                                "vo_duration": "5.5"
                            },
                            "sound_design": {
                                "style": "friendly",
                                "intensity": 5
                            }
                        },
                        "sc_visuals_basics": "Group of students discussing in a cozy environment",
                        "sc_visuals_extensive": "A wide shot of a small group of students gathered around a table, sharing ideas and helping each other.",
                        "sc_success_metric": "Student engagement in community discussions",
                        "sc_count": 0
                    },
                    {
                        "sc_id": "5",
                        "sc_title": "Progress Tracking",
                        "sc_type": "Abstract/Technical",
                        "sc_length_seconds": "7",
                        "sc_purpose": "Demonstrate progress tracking features",
                        "sc_emotion": "subtle",
                        "sc_audio": {
                            "voiceover": {
                                "vo_text": "Track your progress and earn rewards along the way.",
                                "vo_duration": "6.5"
                            },
                            "sound_design": {
                                "style": "motivational",
                                "intensity": 5
                            }
                        },
                        "sc_visuals_basics": "Visuals of progress charts and rewards",
                        "sc_visuals_extensive": "A medium shot showing a student's progress dashboard on their tablet, highlighting milestones and rewards.",
                        "sc_success_metric": "Awareness of progress tracking",
                        "sc_count": 0
                    },
                    {
                        "sc_id": "6",
                        "sc_title": "Call to Action",
                        "sc_type": "Human-focused",
                        "sc_length_seconds": "5",
                        "sc_purpose": "Encourage students to start learning",
                        "sc_emotion": "high intensity",
                        "sc_audio": {
                            "voiceover": {
                                "vo_text": "Unlock your coding potential with our engaging mobile app!",
                                "vo_duration": "4.5"
                            },
                            "sound_design": {
                                "style": "energetic",
                                "intensity": 8
                            }
                        },
                        "sc_visuals_basics": "Students celebrating their achievements",
                        "sc_visuals_extensive": "A wide shot of students cheering and celebrating their achievements together, showcasing joy and accomplishment.",
                        "sc_success_metric": "Increased app downloads and engagement",
                        "sc_count": 0
                    }
                ]
            }
        }
    }
};

export const SAMPLE_GLOW_03 = {
    "status": true,
    "message": "Glow 03 Script completed successfully",
    "status_code": 200,
    "data": {
        "glow_03_response": {
            "1": {
                "video_id": "1_students",
                "audience": "Students",
                "video_duration": "120",
                "scenes": [
                    {
                        "sc_id": "1",
                        "sc_title": "Engaging Introduction",
                        "sc_duration": "4",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Modern and Clean",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, looking at camera, eye contact, facing camera, stock photo",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A diverse group of excited students, ages 18-22, gathered around their screens, wearing casual clothing, with expressions of joy and curiosity, 4 people",
                                "visu_environment": "in a bright, spacious classroom, during the day, with natural light flooding in from large windows",
                                "visu_technical": "captured with a Canon EOS R5 camera using a 24-70mm lens, smooth dolly movement, wide angle perspective",
                                "visu_shot_type": "wide",
                                "visu_subject_treatment": "students engaged in coding activities, showing enthusiasm",
                                "duration_shot": "2",
                                "transition": "fade in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A close-up of a student's face, focused and smiling while coding, wearing a blue hoodie, with an expression of determination, 1 person",
                                "visu_environment": "in the same classroom, with soft overhead lighting enhancing the atmosphere",
                                "visu_technical": "shot with a Nikon Z6 camera using a 50mm lens, slight zoom in, medium close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "student immersed in their coding task",
                                "duration_shot": "2",
                                "transition": "cut to black"
                            }
                        ]
                    },
                    {
                        "sc_id": "2",
                        "sc_title": "Interactive Tutorials",
                        "sc_duration": "6",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Professional",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, stock photo, generic pose",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A close-up of a tablet screen displaying interactive coding tutorials, with vibrant graphics and colorful buttons, 1 device",
                                "visu_environment": "in a cozy study room, during the afternoon, with warm light from a desk lamp",
                                "visu_technical": "captured with a Sony A7 III camera using a macro lens, static shot, close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "tablet showcasing interactive features",
                                "duration_shot": "2",
                                "transition": "slide in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A medium shot of a student actively participating in a coding tutorial on their laptop, wearing glasses and a focused expression, 1 person",
                                "visu_environment": "in a bright classroom with collaborative tables, during the day, with natural light",
                                "visu_technical": "shot with a Fujifilm X-T4 camera using a 35mm lens, slight pan to the right, medium perspective",
                                "visu_shot_type": "medium",
                                "visu_subject_treatment": "student interacting with the tutorial, engaged",
                                "duration_shot": "4",
                                "transition": "fade out"
                            }
                        ]
                    },
                    {
                        "sc_id": "3",
                        "sc_title": "Gamified Learning",
                        "sc_duration": "5",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Modern and Clean",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, perfect smile, posed group shot",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A group of students, ages 18-22, celebrating with high fives after completing a coding game, wearing casual clothing, expressions of excitement, 3 people",
                                "visu_environment": "in a lively classroom, during the day, with bright and cheerful decor",
                                "visu_technical": "shot with a Canon EOS R5 camera using a 24-70mm lens, dynamic tracking shot, wide perspective",
                                "visu_shot_type": "wide",
                                "visu_subject_treatment": "students showing camaraderie and success",
                                "duration_shot": "2",
                                "transition": "zoom in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A close-up of a student's hands on the keyboard, focused on a colorful coding game on the screen, 1 person",
                                "visu_environment": "in the same classroom, with bright lighting highlighting the screen",
                                "visu_technical": "captured with a Nikon Z6 camera using a macro lens, slight tilt down, close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "hands actively coding",
                                "duration_shot": "3",
                                "transition": "fade to black"
                            }
                        ]
                    },
                    {
                        "sc_id": "4",
                        "sc_title": "Real-Time Feedback",
                        "sc_duration": "6",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Professional",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, generic pose",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A close-up of a laptop screen displaying real-time feedback notifications, bright colors and alerts visible, 1 device",
                                "visu_environment": "in a cozy study area, during the evening, with soft ambient lighting",
                                "visu_technical": "shot with a Sony A7 III camera using a macro lens, static shot, close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "screen showing dynamic feedback",
                                "duration_shot": "2",
                                "transition": "fade in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A medium shot of a student reacting positively to feedback on their laptop, smiling, wearing a casual shirt, 1 person",
                                "visu_environment": "in a bright classroom, during the day, with natural light streaming in",
                                "visu_technical": "shot with a Canon EOS R5 camera using a 50mm lens, slight zoom in, medium perspective",
                                "visu_shot_type": "medium",
                                "visu_subject_treatment": "student engaging with feedback",
                                "duration_shot": "4",
                                "transition": "cut to black"
                            }
                        ]
                    },
                    {
                        "sc_id": "5",
                        "sc_title": "Community Support",
                        "sc_duration": "4",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Modern and Clean",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, stiff posture",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A wide shot of a group of students collaborating on coding projects, engaged in discussion, 5 people",
                                "visu_environment": "in a collaborative workspace, during the day, with bright overhead lights",
                                "visu_technical": "shot with a Fujifilm X-T4 camera using a 24mm lens, static shot, wide perspective",
                                "visu_shot_type": "wide",
                                "visu_subject_treatment": "students sharing ideas and support",
                                "duration_shot": "2",
                                "transition": "fade in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A close-up of two students helping each other with coding on a laptop, friendly expressions, 2 people",
                                "visu_environment": "in the same workspace, with warm lighting creating a welcoming atmosphere",
                                "visu_technical": "captured with a Canon EOS R5 camera using a 50mm lens, slight pan to the left, close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "students collaborating closely",
                                "duration_shot": "2",
                                "transition": "fade out"
                            }
                        ]
                    },
                    {
                        "sc_id": "6",
                        "sc_title": "Track Your Progress",
                        "sc_duration": "5",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Professional",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, posed group shot",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A close-up of the app interface showing progress tracking features, with colorful graphs and badges, 1 device",
                                "visu_environment": "in a bright study area, during the late afternoon, with warm sunlight coming through the window",
                                "visu_technical": "captured with a Sony A7 III camera using a macro lens, static shot, close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "app interface displaying progress",
                                "duration_shot": "2",
                                "transition": "fade in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A medium shot of a student looking pleased at their progress on a laptop, wearing a casual t-shirt, 1 person",
                                "visu_environment": "in a cozy classroom, during the day, with bright lighting",
                                "visu_technical": "shot with a Canon EOS R5 camera using a 50mm lens, slight tilt down, medium perspective",
                                "visu_shot_type": "medium",
                                "visu_subject_treatment": "student reflecting on achievements",
                                "duration_shot": "3",
                                "transition": "cut to black"
                            }
                        ]
                    },
                    {
                        "sc_id": "7",
                        "sc_title": "Closing Message",
                        "sc_duration": "5",
                        "visu_style_primary": "Dynamic",
                        "visu_style_secondary": "Modern and Clean",
                        "visu_color_scheme": "Bright Blue and Yellow with Green accents",
                        "visu_quality": "8k, photorealistic, masterpiece quality, professional",
                        "visu_enhancements": "high dynamic range, depth of field, color grading, motion blur",
                        "visu_neg_prompt": "worst quality, bad quality, poor quality, cartoon, anime, illustration, bad anatomy, deformed, extra limbs, bad composition, poor lighting, hands, vacation photo",
                        "shots": [
                            {
                                "shot_number": "001",
                                "shot_type": "START",
                                "visu_main_subject": "A wide shot of students celebrating their achievements in coding, jumping with joy, 6 people",
                                "visu_environment": "in a vibrant classroom, during the day, with colorful decorations",
                                "visu_technical": "captured with a Nikon Z6 camera using a 24mm lens, dynamic tracking shot, wide perspective",
                                "visu_shot_type": "wide",
                                "visu_subject_treatment": "students expressing joy and accomplishment",
                                "duration_shot": "2",
                                "transition": "fade in"
                            },
                            {
                                "shot_number": "002",
                                "shot_type": "ENDING",
                                "visu_main_subject": "A close-up of a student's satisfied face, smiling broadly after coding, wearing a bright t-shirt, 1 person",
                                "visu_environment": "in the same classroom, with bright lighting enhancing their expression",
                                "visu_technical": "shot with a Canon EOS R5 camera using a 50mm lens, slight zoom in, close-up perspective",
                                "visu_shot_type": "close",
                                "visu_subject_treatment": "student reflecting happiness and satisfaction",
                                "duration_shot": "3",
                                "transition": "fade to black"
                            }
                        ]
                    }
                ]
            }
        }
    }
};



export const SAMPLE_GROUP_IMAGES = {
    "1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/sports_fans.jpeg",
    "2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/event_organizers.jpeg"
}

export const SAMPLE_SCENE_IMAGES = {
    "1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_1_engaging_live_streaming_experience.jpeg",
    "1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_2_customizable_overlay.jpeg",
    "1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_3_real-time_scoreboard_integration.jpeg",
    "1_4": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_4_interactive_features_for_engagement.jpeg",
    "1_5": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_5_high-quality_visuals_and_sound.jpeg",
    "1_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_6_call_to_action.jpeg",
    "2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_1_introduction_to_live_streaming.jpeg",
    "2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_2_enhancing_viewer_experience.jpeg",
    "2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_3_seamless_scoreboard_updates.jpeg",
    "2_4": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_4_attracting_sponsors.jpeg",
    "2_5": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_5_integration_with_existing_systems.jpeg",
    "2_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_6_conclusion_and_call-to-action.jpeg"
}

export const SAMPLE_FRAME_IMAGES = {
    "1_1_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_1.jpeg",
    "1_1_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_2.jpeg",
    "1_1_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_3.jpeg",
    "1_2_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_1.jpeg",
    "1_2_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_2.jpeg",
    "1_2_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_3.jpeg",
    "1_3_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_1.jpeg",
    "1_3_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_2.jpeg",
    "1_3_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_3.jpeg",
    "1_4_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_1.jpeg",
    "1_4_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_2.jpeg",
    "1_4_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_3.jpeg",
    "1_5_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_1.jpeg",
    "1_5_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_2.jpeg",
    "1_5_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_3.jpeg",
    "1_6_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_1.jpeg",
    "1_6_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_2.jpeg",
    "1_6_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_3.jpeg",
    "2_1_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_1.jpeg",
    "2_1_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_2.jpeg",
    "2_1_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_3.jpeg",
    "2_2_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_1.jpeg",
    "2_2_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_2.jpeg",
    "2_2_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_3.jpeg",
    "2_3_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_1.jpeg",
    "2_3_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_2.jpeg",
    "2_3_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_3.jpeg",
    "2_4_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_1.jpeg",
    "2_4_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_2.jpeg",
    "2_4_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_3.jpeg",
    "2_5_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_1.jpeg",
    "2_5_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_2.jpeg",
    "2_5_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_3.jpeg",
    "2_6_001": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_1.jpeg",
    "2_6_002": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_2.jpeg",
    "2_6_003": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_3.jpeg"
}