import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'js-cookie';
import processConfig from '../../config/processIndex.json';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  // Update process order to use all chains

const processOrder = [
  ...processConfig.processChains.ideaChain,
  ...processConfig.processChains.videoChain,
  ...processConfig.processChains.advertisementChain
].filter((value, index, self) => self.indexOf(value) === index)

const initialState = {
  data: {
    sessionId: '',
    sessionName: '',
    companyId: '',
    companyName: '',
    companyStatus: '',
    inputPrompt: '',
    glowData: {
      glow_01: { status: false, response: {} },
      glow_02: { status: false, response: {} },
      glow_03: { status: false, response: {} },
      glow_04: { status: false, response: {} },
      glow_08: { status: false, response: {} },
      glow_09: { status: false, response: {} },
      glow_10: { status: false, response: {} },
      glow_11: { status: false, response: {} },
      glow_12: { status: false, response: {} },
      glow_21: { status: false, response: {} },
      glow_33: { status: false, response: {} },
      glow_61: { status: false, response: {} },
      glow_62: { status: false, response: {} },
      glow_63: { status: false, response: {} },
      glow_91: { status: false, response: {} },
    }
  },
  process: '',
  status: 'idle',
  message: '',
};



const handleProcessPayload = async (processId, sessionId, inputPrompt, glowData) => {
  const currentProcessIndex = processOrder.indexOf(processId);
  const prevProcessId = currentProcessIndex > 0 ? processOrder[currentProcessIndex - 1] : null;
  const prevProcessResponse = glowData[prevProcessId] || {};
  
  // Create a default payload for all steps
  const payload = {
    session_id: sessionId,
    input_prompt: inputPrompt,
    process_id: processId,
    previous_process: prevProcessId,
    previous_response: prevProcessResponse,
    options: {
      max_length: processConfig.processes.find(p => p.id === processId)?.validations?.maxLength?.description || 2000
    }
  };

  // Move glow_01's video type handling directly to glow_04
  if (processId === 'glow_04') {
    const glow62Response = glowData['glow_62']['response']['glow_62_response'] || null;
    if (glow62Response && glow62Response.selectedType) {
      payload.process_id = 'glow_01'
      payload.video_type = glow62Response.selectedType.id;
      payload.target_video_duration = parseInt(glow62Response.selectedType.dur) * 60; // Convert minutes to seconds
    } else {
      return 'Please fill in the video format details first in the previous step (Video Type Selection)';
    }
  }
  
  return payload;
}

export const runProcess = createAsyncThunk(
  'session/runProcess',
  async ({ processId, inputData = {} }, { rejectWithValue, getState  }) => {
    // Check if status is loading and process equals processId then return
    const state = getState();
    // if (state.session.status === 'loading' && state.session.process === processId)
    //   return rejectWithValue('Process is already running');
    const process = processConfig.processes.find(p => p.id === processId) || processConfig.initialProcess;
    if (!process) {
      return rejectWithValue(`Process ${processId} not found in configuration`);
    }
    const endpoint = `${process.endpoints.run}`;
    if (!endpoint || endpoint === 'undefined') {
      return { glowId: processId, data: {}}; // Return empty data
    }
    const payload = await handleProcessPayload(processId, state.session.data.sessionId, state.session.data.inputPrompt, state.session.data.glowData);
    if (typeof payload === 'string') {
      return rejectWithValue(payload);
    }
    // Merge inputData with payload
    Object.assign(payload, inputData);

    console.log(`Running ${processId} with payload:`, payload);

    try {
      const token = Cookies.get('token');
      if (!token) {
        return rejectWithValue("Token is required");
      }
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/${endpoint}`,
        payload,
        {
          headers: { 
            Authorization: `Bearer ${token}` 
          }
        }
      );

      if (!response.data.status) {
        return rejectWithValue(`Error running ${processId}: ${response.data.message}`);
      }
      return { glowId: processId, data: response.data.data };
    } catch (error) {
      console.log('error', error);
      // More specific error handling
      if (error.response) {
        // Server responded with error
        return rejectWithValue(error.response.data.message || 'Server error');
      }
      if (error.request) {
        // No response received
        return rejectWithValue('No response from server');
      }
      // Something else went wrong
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

const processSessionData = async (sessionData) => {
  const mappedData = {
    sessionId: sessionData.session_id || '',
    sessionName: sessionData.session_name || '',
    companyId: sessionData.company_id || '',
    companyName: sessionData.company_name || '',
    companyStatus: sessionData.company_status || '',
    inputPrompt: sessionData.input_prompt || '',
    glowData: {
      glow_03: { status: sessionData.glow_03_status || false, response: sessionData.glow_03_data || {} },
      glow_08: { status: sessionData.glow_08_status || false, response: sessionData.glow_08_data || {} },
      glow_09: { status: sessionData.glow_09_status || false, response: sessionData.glow_09_data || {} },
      glow_10: { status: sessionData.glow_10_status || false, response: sessionData.glow_10_data || {} },
      glow_11: { status: sessionData.glow_11_status || false, response: sessionData.glow_11_data || {} },
      glow_12: { status: sessionData.glow_12_status || false, response: sessionData.glow_12_data || {} },
      glow_21: { status: sessionData.glow_21_status || false, response: sessionData.glow_21_data || {} },
      glow_33: { status: sessionData.glow_33_status || false, response: sessionData.glow_33_data || {} },
      glow_61: { status: sessionData.glow_61_status || false, response: sessionData.glow_61_data || {} },
      glow_62: { status: sessionData.glow_62_status || false, response: sessionData.glow_62_data || {} },
      glow_63: { status: sessionData.glow_63_status || false, response: sessionData.glow_63_data || {} },
      glow_91: { status: sessionData.glow_91_status || false, response: sessionData.glow_91_data || {} },
    }
  };

  return mappedData;
}

export const getSessionData = createAsyncThunk(
  'session/getSessionData', 
  async (sessionId, { rejectWithValue }) => {
    const cachedData = localStorage.getItem(sessionId);
    if (cachedData) {
      const sessionData = JSON.parse(cachedData);
      return sessionData;
    } else {
      try {
        const token = Cookies.get('token');
        if (!token) {
          return rejectWithValue("Token is required");
        }
        const response = await axios.post(
          `${API_BASE_URL}/api/v1/session/get_session_details`,
          { session_id: sessionId },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.status) {
          const sessionData = await processSessionData(response.data.data.session_details);
          localStorage.setItem(sessionData.sessionId, JSON.stringify(sessionData));
          // Cache the response
          return sessionData;
        }
        return rejectWithValue('Session data not found');
      } catch (error) {
        console.log('error', error);
        // More specific error handling
        if (error.response) {
          // Server responded with error
          return rejectWithValue(error.response.data.message || 'Server error');
        }
        if (error.request) {
          // No response received
          return rejectWithValue('No response from server');
        }
        // Something else went wrong
        return rejectWithValue(error.message || 'Unknown error');
      }
    }
});

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateGlowData(state, action) {
      const { glowId, data } = action.payload;
      if (state.data.glowData[glowId] && data) {
        state.data.glowData[glowId].status = true;
        state.data.glowData[glowId].response = data;
        localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
        state.status = 'succeeded';
      } else {
        state.status = 'error';
        state.message = 'Invalid glowId or payload data is not valid';
      }
    },
    setSessionId(state, action) {
      state.data.sessionId = action.payload;
    },
    setInputPrompt(state, action) {
      state.data.inputPrompt = action.payload;
    },
    clearSessionData(state) {
      state.data = {...initialState}; // Reset to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSessionData.fulfilled, (state, action) => {
        // Merge action.payload with initialState to make sure no fields is missing
        state.data = { 
          ...initialState.data, 
          ...action.payload,
          glowData: {
            ...initialState.data.glowData,
            ...action.payload.glowData,
          }
        };
        console.log('Session data:', state.data);
        state.status = 'succeeded';
      })
      .addCase(getSessionData.rejected, (state, action) => {
        state.status = 'error';
        state.message = action.payload;
      })
      .addCase(runProcess.pending, (state, action) => {
        state.status = 'loading';
        state.process = action.meta.arg.processId; // Set state.process to processId
      })
      .addCase(runProcess.fulfilled, (state, action) => {      
        const { glowId, data } = action.payload;
        if (state.data.glowData[glowId] && data) {
          state.data.glowData[glowId].status = true;
          state.data.glowData[glowId].response = data;
          localStorage.setItem(state.data.sessionId, JSON.stringify(state.data));
          state.process = ''; // Reset process
          state.status = 'succeeded';
        } else {
          state.status = 'error';
          state.message = `Invalid glowId: ${glowId} or payload data is not valid`;
        }
      })
      .addCase(runProcess.rejected, (state, action) => {
        state.status = 'error';
        state.message = action.payload;
        console.log('Error:', action);
        
      });
  },
});

// Export actions
export const { updateGlowData, clearSessionData, setSessionId, setInputPrompt } = sessionSlice.actions;

export const selectSessionId = (state) => state.session.data.sessionId
export const selectInputPrompt = (state) => state.session.data.inputPrompt
export const selectSessionProcess = (state) => {
  // Geting process name from processConfig by state.session.process
  const process = processConfig.processes.find(p => p.id === state.session.process) || processConfig.initialProcess;
  return process.displayName;
}
export const selectSessionStatus = (state) => state.session.status
export const selectSessionMessage = (state) => state.session.message
export const selectSessionData = (state) => state.session.data
export const selectGlowData = (state, glowId) => {
  if(!state.session.data.glowData) return {}; // Return empty object if glowData is not available
  if(glowId === 'idea_description')
    return {};
  return state.session.data.glowData[glowId] ? state.session.data.glowData[glowId].response : {};
}
export const selectGlowStatus = (state, glowId) => {
  if(!state.session.data.glowData) return false;
  if(glowId === 'idea_description')
    return state.session.data.inputPrompt != '';
  return state.session.data.glowData[glowId] ? state.session.data.glowData[glowId].status : false; // Return false if glowId does not exist
}

// Export reducer
export default sessionSlice.reducer;


