import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchVideoRequests = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/v1/queue_manager/get_video_requests`,
      {
        headers: { 
          Authorization: `Bearer ${Cookies.get('token')}` 
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching video requests:', error);
    throw error;
  }
}; 