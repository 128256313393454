import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './Viewers.css';
import './glow_03_processor_script.css'
import processConfig from '../../config/processIndex.json';
import { Box, CircularProgress, Button } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import { ProcessChainManager } from '../../utils/processChain';
import { generateAndSaveTargetGroupImage } from '../../utils/processUtils';
import ActionBar from './glow_04_action_bar';
import { updateGlowData } from '../../components/session/sessionSlide';
const SAMPLE_DATA = [
  {  
    "id": 1,
    "audience": "Beginner Coders",
    "key_points": [
      "Introduction to the app's features",
      "User-friendly interface for learning coding",
      "Interactive coding challenges and exercises",
      "Progress tracking and rewards system",
      "Community support and resources"
    ],
    "key_points_mapping": "Problem Analysis",
    "main_message": "Empower your coding journey with our mobile app!",
    "main_message_mapping": "Main Message",
    "desired_outcome": "Encourage downloads and engagement with the app",
    "desired_outcome_mapping": "Engagement Strategy",
    "visual_description": "Showcase the app interface, highlight coding exercises, and user testimonials",
    "target_duration_in_sec": "120"
  }
]

const SAMPLE_GROUP_IMAGES = {
  "1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/sports_fans.jpeg",
  "2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/event_organizers.jpeg"
}

const SAMPLE_SCENE_IMAGES = {
  "1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_1_engaging_live_streaming_experience.jpeg",
  "1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_2_customizable_overlay.jpeg",
  "1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_3_real-time_scoreboard_integration.jpeg",
  "1_4": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_4_interactive_features_for_engagement.jpeg",
  "1_5": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_5_high-quality_visuals_and_sound.jpeg",
  "1_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_6_call_to_action.jpeg",
  "2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_1_introduction_to_live_streaming.jpeg",
  "2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_2_enhancing_viewer_experience.jpeg",
  "2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_3_seamless_scoreboard_updates.jpeg",
  "2_4": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_4_attracting_sponsors.jpeg",
  "2_5": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_5_integration_with_existing_systems.jpeg",
  "2_6": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/scene_6_conclusion_and_call-to-action.jpeg"
}

const SAMPLE_FRAME_IMAGES = {
  "1_1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_1.jpeg",
  "1_1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_2.jpeg",
  "1_1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_1_3.jpeg",
  "1_2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_1.jpeg",
  "1_2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_2.jpeg",
  "1_2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_2_3.jpeg",
  "1_3_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_1.jpeg",
  "1_3_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_2.jpeg",
  "1_3_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_3_3.jpeg",
  "1_4_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_1.jpeg",
  "1_4_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_2.jpeg",
  "1_4_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_4_3.jpeg",
  "1_5_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_1.jpeg",
  "1_5_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_2.jpeg",
  "1_5_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_5_3.jpeg",
  "1_6_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_1.jpeg",
  "1_6_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_2.jpeg",
  "1_6_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_1_6_3.jpeg",
  "2_1_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_1.jpeg",
  "2_1_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_2.jpeg",
  "2_1_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_1_3.jpeg",
  "2_2_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_1.jpeg",
  "2_2_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_2.jpeg",
  "2_2_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_2_3.jpeg",
  "2_3_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_1.jpeg",
  "2_3_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_2.jpeg",
  "2_3_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_3_3.jpeg",
  "2_4_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_1.jpeg",
  "2_4_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_2.jpeg",
  "2_4_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_4_3.jpeg",
  "2_5_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_1.jpeg",
  "2_5_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_2.jpeg",
  "2_5_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_5_3.jpeg",
  "2_6_1": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_1.jpeg",
  "2_6_2": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_2.jpeg",
  "2_6_3": "https://chat2movie.s3.us-east-1.amazonaws.com/target_groups/8e5046ab-c20250117061838/frame_2_6_3.jpeg"
}

const Glow03ProcessorScript = ({ data, onComplete, sessionId }) => {
  const dispatch = useDispatch();
  const process = processConfig.processes.find(p => p.id === 'glow_03');

  // Data comes in directly, not wrapped
  const hasRealData = Object.entries(data).length ? true : false ;  
  const [displayData, setDisplayData] = useState(() => {
    const initialData = hasRealData ? data : SAMPLE_DATA;
    return Array.isArray(initialData) ? initialData : [initialData];
  });

  const isDemo = !hasRealData;

  const [isLoading, setIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState(data);

  const [hoverScene, setHoverScene] = useState()
  const [hoverFrame, setHoverFrame] = useState()
  const [hoverRelatedNode, setHoverRelatedNode] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedScene, setSelectedScene] = useState()
  const [selectedFrame, setSelectedFrame] = useState()

  const [groupImages, setGroupImages] = useState({});
  const [loadingGroupImages, setLoadingGroupImages] = useState({});
  const [frameImages, setFrameImages] = useState({});
  const [loadingFrameImages, setLoadingFrameImages] = useState({});
  const [sceneImages, setSceneImages] = useState({});
  const [loadingSceneImages, setLoadingSceneImages] = useState({});
  const [sceneCount, setSceneCount] = useState({});
  const [frameCount, setFrameCount] = useState({});
  const selectedWidth = 70

  React.useEffect(() => {
    // Generate key for scene and frame combine with group number 
    displayData.forEach(group => {
      setSceneCount(prevCount => ({ ...prevCount, [group.id]: group.scenes?.length || 0 }));
      let nbFrame = 0;
      group.scenes?.forEach(scene => {
        scene.key = `${group.id}_${scene.sc_number}`;   
        scene.frames?.forEach(frame => {
          frame.key = `${group.id}_${scene.sc_number}_${frame.frame_number}`;
        });
        nbFrame += scene.frames?.length || 0
      })
      setFrameCount(prevCount => ({ ...prevCount, [group.id]: nbFrame }));
    })
    
    if (displayData && hasRealData) {
      displayData.forEach(group => {
        if (group.audience && !groupImages[group.id]) {
          generateImageForGroup(group, group.id);
        }
        
        group.scenes?.forEach(scene => {
          const sceneKey = `${group.id}_${scene.sc_number}`;
          if (scene.sc_visuals_extensive && !sceneImages[sceneKey]) {
            generateImageForScene(scene, sceneKey);
          }
          scene.frames?.forEach(frame => {
            const frameKey = `${group.id}_${scene.sc_number}_${frame.frame_number}`;
            if (frame.full_visual_prompt && !frameImages[frameKey]) {
              generateImageForFrame(frame, frameKey);
            }
          });
        });
      });
    } else {
      setGroupImages(SAMPLE_GROUP_IMAGES)
      setSceneImages(SAMPLE_SCENE_IMAGES)
      setFrameImages(SAMPLE_FRAME_IMAGES)
    }
    console.log(displayData);
    
  }, [displayData]);

  const updateDisplayData = async (groupId, data) => {
    const updatedData = displayData.map(group => {
      const updatedAudience = {...group, scenes: group.scenes || []};
      if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data)) {
          data.forEach(value => {
            if (value.scenes && (value.audience === group.audience || value.video_id === group.video_id)) {
              updatedAudience.video_id = value.video_id
              updatedAudience.scenes = value.scenes
            }
          });
        } else {
          Object.entries(data).forEach(([key, value]) => {
            if (value.scenes && (value.audience === group.audience || value.video_id === group.video_id)) {
              updatedAudience.video_id = value.video_id
              updatedAudience.scenes = value.scenes
            }
          });
        }
      }
      return updatedAudience;
    });
    
    setDisplayData(updatedData);
    // Dispatch updateGlowData action after updating displayData
    dispatch(updateGlowData({ glowId: groupId, data: { 'glow_03_reponse' : data } }));
  }

  const generateImageForGroup = async (group, groupId) => {
    if (!group.audience || groupImages[groupId]) return;
    
    setLoadingGroupImages(prev => ({ ...prev, [groupId]: true }));
    const targetGroupName = group.audience.toLowerCase().replace(/\s+/g, '_');
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        group.visual_description,
        `${sessionId}/${targetGroupName}`
      );
      
      setGroupImages(prev => ({
        ...prev,
        [groupId]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate group image:', error);
    } finally {
      setLoadingGroupImages(prev => ({ ...prev, [groupId]: false }));
    }
  };

  const generateImageForFrame = async (frame, frameKey) => {
    if (!frame.full_visual_prompt || frameImages[frameKey]) return;
    
    setLoadingFrameImages(prev => ({ ...prev, [frameKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        frame.full_visual_prompt,
        `${sessionId}/frame_${frameKey}`
      );
      
      setFrameImages(prev => ({
        ...prev,
        [frameKey]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate frame image:', error);
    } finally {
      setLoadingFrameImages(prev => ({ ...prev, [frameKey]: false }));
    }
  };

  const generateImageForScene = async (scene, sceneKey) => {
    const sceneName = `scene_${scene.sc_number}_${scene.sc_title.toLowerCase().replace(/\s+/g, '_')}`;
    
    if (!scene.sc_visuals_extensive || sceneImages[sceneKey]) return;
    
    setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        scene.sc_visuals_extensive,
        `${sessionId}/${sceneName}`
      );
      setSceneImages(prev => ({
        ...prev,
        [sceneKey]: imagePaths
      }));
    } catch (error) {
      console.error('Failed to generate scene image:', error);
    } finally {
      setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: false }));
    }
  };

  const toggleGroup = (groupId) => {
  };

  const toggleScene = (sceneKey) => {
    if(selectedScene === sceneKey) setSelectedScene()
    else setSelectedScene(sceneKey); 
  };

  const toggleFrame = (frameKey) => {
    if(selectedScene && selectedFrame === frameKey) setSelectedFrame()
    else setSelectedFrame(frameKey);
  };

  const renderFrame = (frame, groupId, scene, selectedSceneData) => {
    const frameKey = frame.key;
    
    // const isExpanded = expandedFrames[frameKey];
    const isSelected = frameKey === selectedFrame;
    const sceneWidth = selectedScene 
      ? (scene.key === selectedScene ? selectedWidth : (100 - selectedWidth) / Math.max(sceneCount[groupId] - 1, 1)) 
      : 100 / Math.max(sceneCount[groupId], 1);

    const selectedFrameData = (selectedFrame && !!scene) ? scene.frames?.find(frame => frame.key === selectedFrame) : {};

    // This block calculates the width of the frame based on the selected scene and frame states.
    // It initializes the frameWidth to 100% and adjusts it based on whether a scene is selected
    // and whether a specific frame is selected within that scene.
    let frameWidth = `100%`;
    if (selectedSceneData) {
      if (!selectedFrame) {
          // If no frame is selected, set the width based on whether the current scene matches the selected scene.
          if (selectedSceneData.key === scene?.key) {
            frameWidth = `${(1 / scene?.frames?.length) * selectedWidth}%`;
          } else {
            frameWidth = `${(1 / scene?.frames?.length) * sceneWidth}%`;
          }
      } else {
        // If a frame is selected, adjust the width based on the selected frame and scene data.
        if ( selectedSceneData.key === scene.key && selectedFrame === frameKey && selectedFrameData) {
          frameWidth = `${selectedWidth}%`;
        } else {
          frameWidth = `${(100 - selectedWidth) / (frameCount[scene.key] - 1)}%`;
        }
      }
    }

    return (
      <div key={frameKey} className='frame-wrapper' 
        style={{
          width: frameWidth
        }}>
        <div className={`frame-card${selectedScene && selectedScene === scene.key && selectedFrame === frameKey && selectedFrameData ? (isSelected ? ' selected' : ' ') : ''} ${(!selectedScene && hoverScene === scene.key) || (!selectedScene && hoverFrame === groupId) ? ' hover' : ''}`}>
            <div className="frame-image">
              {loadingFrameImages[frameKey] ? (
                <CircularProgress size={24} />
              ) : frameImages[frameKey] ? (
                <img 
                  src={frameImages[frameKey]} 
                  alt={`Visual for Frame ${frameKey}`}
                  onClick={() => {
                    if (selectedScene !== scene.key) {
                      toggleScene(scene.key); 
                    }
                    toggleFrame(frameKey);
                  }}
                  onMouseEnter={() => { setHoverRelatedNode({frame: groupId, scene: scene.key}) }}
                  onMouseLeave={() => { setHoverRelatedNode() }}
                />
              ) : (
                <Button 
                  onClick={(e) => {
                    e.stopPropagation();
                    generateImageForFrame(frame, frameKey);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Generate Frame Visual
                </Button>
              )}
            </div>
        </div>
        {selectedScene && selectedScene === scene.key && selectedFrame === frameKey && selectedFrameData && renderFrameDetail(selectedFrameData)}
      </div>
    );
  };

  const renderFrameDetail = (frameData) => {
    return (
      <div className="frame-details">
        {frameData.primary_person && (
          <div className="frame-item">
            <strong>Primary Person:</strong>
            <p>Description: {frameData.primary_person.description}</p>
            <p>Action: {frameData.primary_person.action}</p>
            <p>Expression: {frameData.primary_person.expression}</p>
          </div>
        )}
        
        {frameData.environment && (
          <div className="frame-item">
            <strong>Environment:</strong>
            <p>Setting: {frameData.environment.setting}</p>
            <p>Background: {frameData.environment.background}</p>
            <p>Props: {frameData.environment.props.join(', ')}</p>
          </div>
        )}
        
        {frameData.technical && (
          <div className="frame-item">
            <strong>Technical:</strong>
            <p>Camera: {frameData.technical.camera}</p>
            <p>Lighting: {frameData.technical.lighting}</p>
            {frameData.technical.color_grade && (
              <p>Color Grade: {frameData.technical.color_grade}</p>
            )}
          </div>
        )}
        
        {frameData.audio && (
          <div className="frame-item">
            <strong>Audio:</strong>
            <p>Narration: {frameData.audio.narration || 'None'}</p>
            <p>Music: {frameData.audio.music}</p>
          </div>
        )}
        
        <div className="frame-details">
          <div className="frame-item">
            <strong>Visual Prompt:</strong>
            <p>{frameData.full_visual_prompt}</p>
          </div>
          <p><strong>Duration:</strong> {frameData.duration}</p>
          <p><strong>Transition:</strong> {frameData.transition}</p>
        </div>
      </div>
    )
  }
  
  const renderScene = (sceneData, groupId, selectedSceneData) => {    
    const sceneKey = sceneData.key;
    const nbFrame = sceneData?.frames?.length || 0;
    // const isExpanded = expandedScenes[sceneKey];
    const isSelected = selectedScene && sceneData.key === selectedScene;
    const otherWidth = (100 - selectedWidth) /  Math.max(1, sceneCount[groupId] - 1)
    const sceneWidth = selectedScene && selectedSceneData 
        ? (isSelected ? selectedWidth : otherWidth) 
        : nbFrame ? nbFrame /  Math.max(1, frameCount[groupId]) * 100
        : 100 / sceneCount[groupId];

    return (
      <div key={sceneKey} className='scene-wrapper' 
        style={{ width: `${sceneWidth}%` }}>
        <div className={`scene-card${selectedScene ? isSelected ? ' selected' : '' : ''} ${(!selectedScene && hoverFrame === groupId) || (!selectedScene && hoverRelatedNode?.scene === sceneKey) ? 'hover' : ''}`}>
          <div className="scene-image">
            {loadingSceneImages[sceneKey] ? (
              <CircularProgress size={24} />
            ) : sceneImages[sceneKey] ? (
              <img 
                src={sceneImages[sceneKey]} 
                alt={`Visual for Scene ${sceneKey}`}
                onClick={() => {
                  toggleScene(sceneKey)
                  setSelectedFrame()
                }}
                onMouseEnter={() => { setHoverScene(sceneKey); setHoverRelatedNode({frame: groupId}) }}
                onMouseLeave={() => { setHoverScene(); setHoverRelatedNode() }}
              />
            ) : (
              <Button 
                onClick={() => generateImageForScene(sceneData, sceneKey)}
                variant="outlined"
                size="small"
              >
                Generate Scene Visual
              </Button>
            )}
          </div>
        </div>
        {selectedScene && selectedScene === sceneData.key && renderSceneDetail(sceneData)}
      </div>
    );
  };

  const renderSceneDetail = (sceneData) => {
    return (
      <div className="scene-details">
        <p><strong>Scene Length:</strong> {sceneData.sc_length}</p>
        <p><strong>Purpose:</strong> {sceneData.purpose}</p>
        <p><strong>Emotion:</strong> {sceneData.emotion}</p>
        <div className="scene-item">
          <strong>Visual Basics:</strong>
          <p>{sceneData.sc_visuals_basics}</p>
        </div>
        <div className="scene-item">
          <strong>Visual Details:</strong>
          <p>{sceneData.sc_visuals_extensive}</p>
        </div>
        <div className="scene-item">
          <strong>Background Audio:</strong>
          <p>{sceneData.sc_audio_background}</p>
        </div>
        <div className="scene-item">
          <strong>Success Metric:</strong>
          <p>{sceneData.success_metric}</p>
        </div>
      </div>
    )
  }
  const renderTargetGroup = (groupData, groupId) => {
    const selectedSceneData = selectedScene ? groupData.scenes.find(scene => scene.key === selectedScene) : {};
    
    return (
      <div key={`group-${groupId}`} className="target-group glow_03">
        <div className={`group-content ${(!selectedScene && hoverFrame === groupId) || (!selectedScene && hoverRelatedNode?.frame === groupId) ? 'hover' : ''}`}
              onMouseEnter={() => { setHoverFrame(groupId) }}
              onMouseLeave={() => { setHoverFrame()}}>
          {sceneCount[groupId] === 0 && <ActionBar currentLevel={0} updateData={updateDisplayData} groupId={groupId}/>}
          <div className='group-card'>
            <div className="group-image" onClick={() => toggleGroup(groupId)}>
              {loadingGroupImages[groupId] ? (
                <CircularProgress size={24} />
              ) : groupImages[groupId] ? (
                <img 
                  src={groupImages[groupId]} 
                  alt={`Visual for ${groupData.audience}`}
                  onClick={() => toggleGroup(groupId)}
                />
              ) : (
                <Button 
                  onClick={() => generateImageForGroup(groupData, groupId)}
                  variant="outlined"
                  size="small"
                >
                  Generate Visual
                </Button>
              )}
            </div>
          </div>

          <div className="group-detail">
            <h4>Video Base Information</h4>
            <p><strong>Description:</strong> {groupData.visual_description}</p>
            <p><strong>Duration:</strong> {groupData.target_duration_in_sec} seconds</p>
            <p><strong>Message:</strong> {groupData.main_message}</p>
          </div>
          
          <div className="group-detail">
            <h4>Key points</h4>
          <ul>
            {groupData.key_points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          </div>
        </div>
        
        <div className="scene-content">
          {sceneCount[groupId] > 0 && frameCount[groupId] === 0 && <ActionBar currentLevel={1} updateData={updateDisplayData} groupId={groupId} />}
          {groupData.scenes?.map(scene => renderScene(scene, groupId, selectedSceneData))}
        </div>
        
        <div className="frame-content">
          {groupData.scenes?.map(scene => scene.frames?.map(frame => renderFrame(frame, groupId, scene, selectedSceneData)))}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="process-viewer">
        <Box>
          <ViewerHeader 
            title={process.info.title}
            subtitle={process.info.description}
            processId="glow_03"
            sessionId={sessionId}
            showRerun={!!process.endpoints.run}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '200px' 
          }}>
            <CircularProgress />
          </Box>
        </Box>
      </div>
    );
  }

  if (!displayData) {
    return <div>No data available</div>;
  }

  return (
    <div className="process-viewer">
      <Box>
        {isDemo && (
          <Box sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            color: 'rgba(255, 140, 0, 0.45)',
            fontSize: '48px',
            fontWeight: 'bold',
            pointerEvents: 'none',
            zIndex: 9999,
            width: '100%',
            textAlign: 'center',
            userSelect: 'none',
            textTransform: 'uppercase'
          }}>
            Sample (Glowtest)
          </Box>
        )}
        <ViewerHeader 
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_03"
          sessionId={sessionId}
          showRerun={!!process.endpoints.run}
        />
        { displayData.map(group => (
            renderTargetGroup(group, group.id)
          ))
        }
        <ViewFooter
          isDisabled={!sessionId} 
          onComplete={onComplete}
          buttonText={process.footer.continueText}
        />
      </Box>
    </div>
  );
};

export default Glow03ProcessorScript;