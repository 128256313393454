import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  vertical: 'top',
  horizontal: 'center',
  duration: 4000,
  message: '',
  severity: 'info', // default severity
  icon: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      const { message, icon, severity, vertical, horizontal, duration } = action.payload;
      console.log(`Notice: ${message}`);
      
      return {
        ...initialState,
        open: true,
        message: message,
        duration: duration || 4000,
        severity: severity || 'info', // default to 'info' if not provided
        icon: icon || null, // default to null if not provided
        vertical: vertical || state.vertical, // retain previous vertical if not provided
        horizontal: horizontal || state.horizontal, // retain previous horizontal if not provided
      };
    },
    closeNotification: (state) => {
      state.open = false;
      state.message = '';
      state.severity = 'info';
      state.icon = null;
    },
  },
});

export const { showNotification, closeNotification } = notificationSlice.actions;
export const selectNotification = (state) => state.notification;

export default notificationSlice.reducer;