import React from 'react';
import PropTypes from 'prop-types';
import './CreditFlag.css'; // Optional: for custom styles

const ANONYMOUS_USER = 'user01_unreg';
const REGISTER_USER = 'user02_reg';
const PAYING_USER = 'user03_paying';
const PLUS_USER = 'user03_01_plus';
const PRO_USER = 'user03_02_pro';
const PREMIUM_USER = 'user03_03_premium';
const LIFETIME_USER = 'user03_04_lifetime';

const USER_TYPES = {
  [ANONYMOUS_USER]: 'guest',
  [REGISTER_USER]: 'register',
  [PAYING_USER]: 'paying',
}

const SUBCRIPTION_TYPES = {
  [PLUS_USER]: 'plus',
  [PRO_USER]: 'pro',
  [PREMIUM_USER]: 'premium',
  [LIFETIME_USER]: 'lifetime',
}

const SUBCRIPTION_TYPES_ICON = {
  [PLUS_USER]: '/images/plan-plus.svg',
  [PRO_USER]: '/images/plan-pro.svg',
  [PREMIUM_USER]: '/images/plan-premium.svg',
  [LIFETIME_USER]: '/images/plan-lifetime.svg',
}

const CreditFlag = ({ accountInfo }) => {
  const userType = accountInfo.userType;
  const subscriptionPlan = accountInfo.subscriptionType;
  const credit = Number(accountInfo.credit).toFixed(2);

  return (
    <div id="subscriptions-center" className={`credit-flag ${subscriptionPlan}`}>
      <span id="subscriptions-info">
        {userType === PAYING_USER && (
          <>
            <img src={SUBCRIPTION_TYPES_ICON[subscriptionPlan]} alt="Plan Icon" />
            {SUBCRIPTION_TYPES[subscriptionPlan]}
          </>
        )}
        {userType !== PAYING_USER && 'Credits'}
      </span>
      <span id="credit-balance">{(userType != PAYING_USER) ? credit : ''}</span>
    </div>
  );
};

CreditFlag.propTypes = {
  accountInfo: PropTypes.object.isRequired,
};

export default CreditFlag;