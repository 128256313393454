import React from 'react';
import { Box } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import PricingDashboard from './glow_11_viewer_prodprice';
import processConfig from '../../config/processIndex.json';

const SAMPLE_DATA = {
  glow_11_response: {
    product_cost_breakdown: [
      { component: "Software Development", cost: 15000 },
      { component: "AI Model Training", cost: 5000 },
      { component: "Video Hosting Services", cost: 2000 },
      { component: "Marketing and Advertising", cost: 3000 },
      { component: "Customer Support", cost: 2500 },
      { component: "Miscellaneous Expenses", cost: 1000 }
    ],
    market_pricing: [
      { segment: "Small Businesses", price: 99 },
      { segment: "Medium Enterprises", price: 299 },
      { segment: "Large Corporations", price: 499 }
    ],
    suggested_price_points: [
      { segment: "Small Businesses", price: 89 },
      { segment: "Medium Enterprises", price: 249 },
      { segment: "Large Corporations", price: 449 }
    ],
    customer_metrics: {
      cac: {
        "Small Businesses": 20,
        "Medium Enterprises": 50,
        "Large Corporations": 100
      },
      ltv: {
        "Small Businesses": 300,
        "Medium Enterprises": 800,
        "Large Corporations": 1500
      }
    },
    period: "monthly"
  }
};

const Glow11Processor = ({ data, onComplete, sessionId }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_11');  
  console.log('Glow11Processor received data:', data);

  // Data comes in directly, not wrapped
  const marketData = data?.glow_11_response || data?.market_analysis || data;
  const hasRealData = marketData?.product_cost_breakdown;
  const displayData = hasRealData ? { glow_11_response: marketData } : SAMPLE_DATA;
  const isDemo = !hasRealData;

  if (!process) {
    return <div>Process configuration not found</div>;
  }

  return (
    <Box>
      {isDemo && (
        <Box sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-45deg)',
          color: 'rgba(255, 140, 0, 0.45)',
          fontSize: '48px',
          fontWeight: 'bold',
          pointerEvents: 'none',
          zIndex: 9999,
          width: '100%',
          textAlign: 'center',
          userSelect: 'none',
          textTransform: 'uppercase'
        }}>
          Sample (Glowtest)
        </Box>
      )}
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_11"
        sessionId={sessionId}
        showRerun={!!process.endpoints.run}
      />
      <PricingDashboard data={displayData.glow_11_response} />
      <ViewFooter
        isDisabled={!sessionId} 
        onComplete={onComplete}
        buttonText={process?.footer?.continueText || "Continue"}
      />
    </Box>
  );
};

export default Glow11Processor;