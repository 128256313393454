import React, { useState } from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { runProcess } from '../session/sessionSlide';
import { showNotification } from '../notification/notificationSlide';
import { SAMPLE_GLOW_02, SAMPLE_GLOW_03 } from '../../data/sampleGlowData';

const process = ['Scenes', 'Frames']

const ActionBar = ({ currentLevel, updateData, groupId, isDemo }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const nextProcess = process[currentLevel] || '';

    const handleEdit = () => {
        console.log('Edit action triggered');
        dispatch(
            showNotification({
                message: "The action is currently under construction.",
                severity: "success",
            })
        );
    };

    const handleRedo = () => {
        console.log('Redo action triggered');
        dispatch(
            showNotification({
                message: "The action is currently under construction.",
                severity: "success",
            })
        );
    };

    const handleContinue = () => {
        if (loading) return; // Prevent multiple triggers
        setLoading(true);
        console.log('Continue action triggered');
        if (currentLevel === 0) {
            if(isDemo) {
                updateData(groupId, SAMPLE_GLOW_02.data.glow_02_response, 'glow_02')
                dispatch(
                    showNotification({
                        message: "Process glow_02 executed successfully",
                        severity: "success",
                    })
                );
                setLoading(false);
            } else 
                dispatch(runProcess({ processId: 'glow_02', inputData: { 'audience_id' : `${groupId}` } }))
                .unwrap()
                .then((response) => {
                    console.log('runProcess success, response:', response);
                    if (response && response.data && response.data.glow_02_response) {
                        if (Object.keys(response.data.glow_02_response).length === 0) {
                            throw new Error("The response contains empty data.");
                        }
                        updateData(groupId, response.data.glow_02_response, 'glow_02');
                        dispatch(
                            showNotification({
                                message: "Process glow_02 executed successfully",
                                severity: "success",
                            })
                        );
                    } else {
                        throw new Error("Response does not contain 'glow_02_response'");
                    }
                })
                .catch((error) => {
                    console.error('runProcess encountered an error:', error);
                    dispatch(
                        showNotification({
                            message: `Process glow_02 failed: ${error.message}`,
                            severity: "error",
                        })
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (currentLevel === 1) {
            if(isDemo) {
                updateData(groupId, SAMPLE_GLOW_03.data.glow_03_response, 'glow_03')
                dispatch(
                    showNotification({
                        message: "Process glow_03 executed successfully",
                        severity: "success",
                    })
                );
                setLoading(false);
            } else 
                dispatch(runProcess({ processId: 'glow_03', inputData: { 'audience_id' : `${groupId}` } }))
                .unwrap()
                .then((response) => {
                    console.log('runProcess glow_03 success, response:', response,);
                    if (response && response.data && response.data.glow_03_response) {
                        if (Object.keys(response.data.glow_03_response).length === 0) {
                            throw new Error("The response contains empty data.");
                        }
                        updateData(groupId, response.data.glow_03_response, 'glow_03');
                        dispatch(
                            showNotification({
                                message: "Process glow_03 executed successfully",
                                severity: "success",
                            })
                        );
                    } else {
                        throw new Error("Response does not contain 'glow_03_response'");
                    }
                })
                .catch((error) => {
                    console.error('runProcess glow_03 encountered an error:', error);
                    dispatch(
                        showNotification({
                            message: `Process glow_03 failed: ${error.message}`,
                            severity: "error",
                        })
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <Box
            className='action-bar-containter'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}
        >
            <Button variant="outlined" onClick={handleEdit}>Edit</Button>
            <Button variant="outlined" onClick={handleRedo}>Redo</Button>
            <span style={{ flexGrow: 1 }} />
            <Button
                variant="outlined"
                onClick={handleContinue}
                sx={{ flexDirection: 'column' }}
                disabled={loading}
            >
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        Produces {nextProcess} & Continue
                        <span style={{ fontSize: '1.2rem', alignSelf: 'center' }}>▼</span>
                    </>
                )}
            </Button>
        </Box>
    );
};

export default ActionBar;
