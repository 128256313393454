import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  glowSteps: {
    glow_61: null,
    glow_62: null,
    glow_63: null,
    glow_08: {
      answers: {
        stage: null,
        validation: null,
        team_expertise: null,
        team: null,
        budget: null,
        goal: null
      },
      status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
      error: null
    },
    glow_33: {
      answers: {
        amount: null,
        groupNames: [],
        goal: null
      },
      status: 'idle',
      error: null
    }
  },
  status: 'idle',
  error: null
};

const glowStepsSlice = createSlice({
  name: 'glowSteps',
  initialState,
  reducers: {
    updateGlowStep: (state, action) => {
      const { stepId, data } = action.payload;
      console.log(`Updating ${stepId} with:`, data); // Debug
      state.glowSteps[stepId] = data;
    },
    clearGlowSteps: (state) => {
      console.log('Clearing all glow steps'); // Debug
      state.glowSteps = initialState.glowSteps;
    },
    updateGlow08Answer: (state, action) => {
      state.glowSteps.glow_08.answers = {
        ...state.glowSteps.glow_08.answers,
        ...action.payload
      };
    },
    setGlow08Status: (state, action) => {
      state.glowSteps.glow_08.status = action.payload;
    },
    clearGlow08: (state) => {
      state.glowSteps.glow_08 = {
        answers: {
          stage: null,
          validation: null,
          team_expertise: null,
          team: null,
          budget: null,
          goal: null
        },
        status: 'idle',
        error: null
      };
    },
    updateGlow33Answer: (state, action) => {
      console.log('Updating glow_33 answer:', action.payload);
      state.glowSteps.glow_33.answers = {
        ...state.glowSteps.glow_33.answers,
        ...action.payload
      };
    },
    setGlow33Status: (state, action) => {
      state.glowSteps.glow_33.status = action.payload;
    },
    clearGlow33: (state) => {
      state.glowSteps.glow_33 = {
        answers: {
          amount: null,
          groupNames: [],
          goal: null
        },
        status: 'idle',
        error: null
      };
    }
  }
});

export const {
  updateGlowStep,
  clearGlowSteps,
  updateGlow08Answer,
  setGlow08Status,
  clearGlow08,
  updateGlow33Answer,
  setGlow33Status,
  clearGlow33
} = glowStepsSlice.actions;

// Selectors
export const selectGlowStep = (state, stepId) => {
  console.log(`Selecting ${stepId}:`, state.glowSteps.glowSteps[stepId]); // Debug
  return state.glowSteps.glowSteps[stepId];
};
export const selectAllGlowSteps = (state) => state.glowSteps.glowSteps;
export const selectGlow08Answers = (state) => state.glowSteps?.glow_08?.answers || {
  stage: null,
  validation: null,
  team_expertise: null,
  team: null,
  budget: null,
  goal: null
};
export const selectGlow08Status = (state) => state.glowSteps?.glow_08?.status || 'idle';
export const selectGlow33Answers = (state) => state.glowSteps?.glowSteps?.glow_33?.answers || {
  amount: null,
  groupNames: [],
  goal: null
};
export const selectGlow33Status = (state) => state.glowSteps?.glowSteps?.glow_33?.status || 'idle';

export default glowStepsSlice.reducer; 