import React, { useState, useEffect } from 'react';
import ViewerHeader from '../common/ViewerHeader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Slider,
  Divider,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  AlertCircle,
  CheckCircle,
  Clock,
  Play,
  Pause,
  RefreshCw,
  Eye,
  Filter,
  ArrowUpDown
} from 'lucide-react';
import processConfig from '../../config/processIndex.json';
import { VIDEO_INDEX } from './glow_62_processor_customer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchVideoRequests } from '../../utils/apiUtils';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllGlowSteps } from '../../redux/glowStepsSlice';
import { updateGlowStep } from '../../redux/glowStepsSlice';
import SelectionCard from './glow_61_selection_card';
import QuestionMarkIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const VIDEO_TYPES = Object.entries(VIDEO_INDEX.categories)
  .flatMap(([categoryId, category]) => 
    Object.entries(category.types)
      .filter(([_, type]) => type.status === 2)
      .map(([typeId, type]) => ({
        id: typeId.toUpperCase(),
        name: type.name,
        duration: type.dur,
        category: category.name,
        abbr: type.abbr
      }))
  );

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const S3_BASE_URL = `https://${process.env.REACT_APP_DEV_AWS_BUCKET_NAME || 'chat2movie'}.s3.${process.env.REACT_APP_DEV_AWS_REGION || 'us-east-1'}.amazonaws.com`;

const getS3Url = (path) => {
  const styleNameMap = {
    'realistic_image': 'realistic',
    'digital_illustration': 'digital',
    'vector_illustration': 'vector',
    'icon': 'icon',
    'logo_raster': 'logo'
  };

  let url;
  const cleanPath = path.replace(/^(thumbnails\/|full\/)/, '');

  if (path.startsWith('thumbnails/')) {
    if (cleanPath.includes('substyles/')) {
      const substyleName = cleanPath.split('/').pop();
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/substyles/${substyleName}`;
    } else if (cleanPath.includes('styles/')) {
      const styleName = cleanPath.split('/').pop().replace('.webp', '');
      const mappedName = styleNameMap[styleName] || styleName;
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/styles/${mappedName}.webp`;
    } else if (cleanPath.includes('providers/') || cleanPath.includes('models/')) {
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/${cleanPath}`;
    }
  } else if (path.startsWith('full/')) {
    if (cleanPath.includes('substyles/')) {
      const substyleName = cleanPath.split('/').pop();
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/substyles/${substyleName}`;
    } else if (cleanPath.includes('styles/')) {
      const styleName = cleanPath.split('/').pop().replace('.webp', '');
      const mappedName = styleNameMap[styleName] || styleName;
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/styles/${mappedName}.webp`;
    } else if (cleanPath.includes('providers/') || cleanPath.includes('models/')) {
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/${cleanPath}`;
    }
  }

  return url;
};

const STATUS_BADGES = {
  pending: { color: 'bg-yellow-100 p-1 text-yellow-800', icon: Clock },
  processing: { color: 'bg-blue-100 p-1 text-blue-800', icon: RefreshCw },
  completed: { color: 'bg-green-100 p-1 text-green-800', icon: CheckCircle },
  failed: { color: 'bg-red-100 p-1 text-red-800', icon: AlertCircle },
};

const SAMPLE_VIDEOS = [
  // {
  //   id: 1,
  //   input_prompt: "Create a validation video for an AI-powered knowledge visualization tool",
  //   video_type: "PV",
  //   status: "completed",
  //   created_at: "2024-01-02T10:00:00Z",
  //   started_at: "2024-01-02T10:01:00Z",
  //   completed_at: "2024-01-02T10:05:00Z",
  //   estimated_duration: "5 minutes",
  //   actual_duration: "4 minutes",
  //   voice_by: "morgan_freeman",
  //   target_video_duration: 30,
  //   preview_url: "/api/placeholder/640/360",
  //   progress: 100,
  // },
  // {
  //   id: 2,
  //   input_prompt: "Create an API guide for REST endpoints",
  //   video_type: "AG",
  //   status: "processing",
  //   created_at: "2024-01-02T10:10:00Z",
  //   started_at: "2024-01-02T10:11:00Z",
  //   estimated_duration: "6 minutes",
  //   voice_by: "female_ref",
  //   target_video_duration: 45,
  //   progress: 60,
  // },
];

// Add this constant for workflow types
const WORKFLOW_TYPES = {
  CW12: {
    id: 'cw12_recraft',
    name: 'High Speed Premium Pipeline',
    description: 'Recraft API & SD1.1',
    image: `${S3_BASE_URL}/pipelines/high-speed-pipeline.webp`,
    placeholderImage: `${S3_BASE_URL}/placeholders/high-speed-placeholder.webp`
  },
  CW11: {
    id: 'cw11_sd',
    name: 'Slow Pipeline',
    description: 'SD3.5 & SD1.1',
    image: `${S3_BASE_URL}/pipelines/slow-pipeline.webp`,
    placeholderImage: `${S3_BASE_URL}/placeholders/slow-pipeline-placeholder.webp`
  }
};

const VideoRequestManager = ({ sessionId, showNotice }) => {  
  const process = processConfig.processes.find(p => p.id === 'glow_91');
  const [videos, setVideos] = useState(SAMPLE_VIDEOS);
  const [newRequest, setNewRequest] = useState({
    input_prompt: '',
    video_type: 'PV',
    target_video_duration: 30,
    voice_by: 'morgan_freeman',
    audience_number: 1,
    audience_list: [],
    fps: 12,
    augmentation_level: 0.02,
    width: 1280,
    height: 720,
    workflow_type: 'cw12_recraft', // Default to CW12
    cw11_sd_config: {  // Renamed from cw11_config
      video_sampler_steps: 30,
      video_sampler_cfg: 3.5,
      video_sampler_sampler_name: "euler_ancestral",
      video_sampler_scheduler: "karras",
      video_sampler_denoise: 1,
      sampler_steps: 40,
      sampler_cfg: 7,
      sampler_name: "dpmpp_2s_ancestral",
      sampler_scheduler: "normal",
      sampler_denoise: 1
    },
    cw12_recraft_config: {
      model: "recraftv3",
      size: "1820x1024",
      quality: "standard",
      style: "realistic_image",
      extra_body: {
        substyle: "",
        artistic_level: 0,
        no_text: true
      },
      video_sampler_steps: 30,
      video_sampler_cfg: 3.5,
      video_sampler_sampler_name: "euler_ancestral",
      video_sampler_scheduler: "karras",
      video_sampler_denoise: 1
    }
  });
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [previewURL, setPreviewURL] = useState(null)
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info' // 'error', 'warning', 'info', 'success'
  });
  const [successPopup, setSuccessPopup] = useState({
    open: false,
    sessionId: '',
    message: ''
  });

  const glowSteps = useSelector(selectAllGlowSteps);
  const dispatch = useDispatch();

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const formatDuration = (seconds) => {
    return `${seconds} seconds`;
  };

  const formatDateTime = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const calculateProgress = (video) => {
    if (video.status === 'completed') return 100;
    if (video.status === 'pending') return 0;
    return video.progress || 0;
  };

  const calculateVideoProgress = (video) => {
    // If pending, return 0
    if (video.request_status === "pending") return 0;
    if (video.request_status === "completed") return 100;
    
    const stages = [
      { status: video.glow1_status, weight: 25 },
      { status: video.glow2_status, weight: 25 },
      { status: video.glow3_status, weight: 25 },
      { status: video.workflow_status, weight: 25 }
    ];

    return stages.reduce((total, stage) => {
      return total + (stage.status === "Completed" ? stage.weight : 0);
    }, 0);
  };

  const handleFetchVideoRequests = async () => {
    setIsLoading(true);
    try {
      const data = await fetchVideoRequests();
      if (data.status && data.status_code === 200) {
        const videoList = data.data.map((video) => {
          const isPending = video.request_status === "pending";
          
          return {
            id: video.session_id,
            video_type: video.video_type,
            input_prompt: video.user_input,
            status: video.request_status.toLowerCase(),
            estimated_duration: isPending ? 'Calculating...' : (video.glow1_duration || 'N/A'),
            actual_duration: isPending ? 'Pending...' : (video.workflow_duration || 'N/A'),
            progress: calculateVideoProgress(video),
            created_at: video.process_creation_time || new Date().toISOString(),
            started_at: video.glow1_start_time || 'Not started',
            completed_at: video.workflow_end_time || 'Not completed',
            resolution: `${video.width}x${video.height}`,
            voice_by: video.voice_by,
            target_duration: video.target_video_duration,
            preview_url: "/api/placeholder/640/360",
            // Status information with pending states
            current_process: isPending ? 'Queued' : (video.current_process || 'Unknown'),
            process_status: isPending ? 'Waiting' : (video.process_status || 'Unknown'),
            last_updated: video.process_last_update_time || video.process_creation_time || 'Just now',
            // Add stage statuses with pending states
            stages: {
              glow1: {
                status: isPending ? 'Waiting' : (video.glow1_status || 'Not started'),
                startTime: video.glow1_start_time || null,
                endTime: video.glow1_end_time || null,
                duration: video.glow1_duration || null
              },
              glow2: {
                status: isPending ? 'Waiting' : (video.glow2_status || 'Not started'),
                startTime: video.glow2_start_time || null,
                endTime: video.glow2_end_time || null,
                duration: video.glow2_duration || null
              },
              glow3: {
                status: isPending ? 'Waiting' : (video.glow3_status || 'Not started'),
                startTime: video.glow3_start_time || null,
                endTime: video.glow3_end_time || null,
                duration: video.glow3_duration || null
              },
              workflow: {
                status: isPending ? 'Waiting' : (video.workflow_status || 'Not started'),
                startTime: video.workflow_start_time || null,
                endTime: video.workflow_end_time || null,
                duration: video.workflow_duration || null
              }
            }
          };
        });
        
        setVideos(videoList);
        
        if (data.data.length === 0) {
          setNotification({
            open: true,
            message: 'No video requests found',
            severity: 'info'
          });
        }
      }
    } catch (error) {
      console.error('Failed to fetch video requests:', error);
      setNotification({
        open: true,
        message: 'Failed to fetch video requests',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchVideoRequests();
  }, []);

  useEffect(() => {
    console.log('Glow91: Pre-filling with data from glow_61:', glowSteps.glow_61);
    setNewRequest(prev => ({
      ...prev,
      input_prompt: glowSteps.glow_61?.prompt || prev.input_prompt,
      audience_number: glowSteps.glow_61?.audienceSize || prev.audience_number,
      audience_list: glowSteps.glow_61?.audienceList || prev.audience_list,
    }));
  }, [glowSteps]);

  const handleAddRequestToQueue = async (requestBody) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/queue_manager/add_request_to_queue`, 
        requestBody,
        { headers: { Authorization: `Bearer ${Cookies.get('token')}` }}
      );

      const data = await response.data();
      if (data.status && data.message === "Request added to queue successfully" && data.status_code === 200) {
        console.log('Request added to queue:', data);
        showNotice('Request added to queue!')
        setSuccessPopup({
          open: true,
          sessionId: data.session_id,
          message: data.message
        });
        return data;
      } else {
        console.error('Failed to add request to queue:', data);
        showNotice('Failed to add request to queue', 'error')
        return { status: false, message: 'Failed to add request to queue' };
      }
      // Optionally update state or handle success
    } catch (error) {
      console.error('Error adding request to queue:', error);
      // Optionally handle error
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (newRequest.workflow_type === 'cw12_recraft') {
      dispatch(updateGlowStep({
        step: 'glow_61',
        data: {
          ...glowSteps.glow_61,
          prompt: newRequest.input_prompt,
          audienceSize: newRequest.audience_number,
          audienceList: newRequest.audience_list,
        }
      }));
    }

    const requestBody = {
      ...newRequest,
      session_id: sessionId,
    };

    handleAddRequestToQueue(requestBody);
  };

  // Simplified workflow image getter
  const getWorkflowImage = (workflowType) => {
    if (workflowType === 'cw12_recraft') {
      const { provider, model, style } = glowSteps.glow_61 || {};
      if (provider && model && style) {
        return getS3Url(`providers/${provider}/models/${model}/styles/${style}.webp`);
      }
      return null;
    }
    return WORKFLOW_TYPES.CW11.image;
  };

  const workflowTypeSelector = (
    <div className="mb-6">
      <Typography variant="h6" gutterBottom>
        Select Pipeline
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {Object.values(WORKFLOW_TYPES).map((workflow) => {
          const workflowImage = getWorkflowImage(workflow.id);
          
          return (
            <SelectionCard
              key={workflow.id}
              title={workflow.name}
              description={workflow.description}
              selected={newRequest.workflow_type === workflow.id}
              onClick={() => setNewRequest(prev => ({ ...prev, workflow_type: workflow.id }))}
              thumbnailSrc={workflowImage || workflow.placeholderImage}
              fullImageSrc={workflowImage || workflow.placeholderImage}
            >
              {!workflowImage && workflow.id === 'cw12_recraft' && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    color: 'text.secondary'
                  }}
                >
                  <QuestionMarkIcon sx={{ fontSize: 48 }} />
                  <Typography variant="caption">
                    Complete Video Format selection first
                  </Typography>
                </Box>
              )}
            </SelectionCard>
          );
        })}
      </Box>
      {newRequest.workflow_type && (
        <Box sx={{ mt: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2
          }}>
            <img 
              src={getWorkflowImage(newRequest.workflow_type) || 
                   Object.values(WORKFLOW_TYPES).find(w => w.id === newRequest.workflow_type)?.placeholderImage}
              alt=""
              style={{ 
                width: '100px',
                height: '60px',
                objectFit: 'cover',
                borderRadius: '4px'
              }}
            />
          </Box>
        </Box>
      )}
    </div>
  );

  // Simplified audience size input without confirmation
  const audienceTargetingSection = (
    <div className="mb-6">
      <div>
        <label className="block text-sm font-medium mb-1">Target Audience Size</label>
        <TextField
          type="number"
          value={newRequest.audience_number}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setNewRequest(prev => ({ ...prev, audience_number: value }));
            if (newRequest.workflow_type === 'cw12_recraft') {
              dispatch(updateGlowStep({
                step: 'glow_61',
                data: {
                  ...glowSteps.glow_61,
                  audienceSize: value
                }
              }));
            }
          }}
          fullWidth
          inputProps={{ min: "1" }}
        />
      </div>
    </div>
  );

  return (
    <>
      <ViewerHeader
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_91"
        showRerun={!!process.endpoints.run}
      />
      <Box sx={{ p: 3 }}>
        <Card className="mb-8">
          <CardHeader title="New Video Request" />
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              {workflowTypeSelector}
              {audienceTargetingSection}
              <div className="flex flex-column-md flex-row items-center justify-between gap-4">
                <div className="flex-grow">
                  <label className="block text-sm font-medium mb-1">Video Type</label>
                  <Select
                    value={newRequest.video_type}
                    onChange={(e) => setNewRequest({ ...newRequest, video_type: e.target.value })}
                    fullWidth
                  >
                    {VIDEO_TYPES.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        <Box>
                          <Typography variant="subtitle2">{type.name}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {type.category} • {type.duration} • {type.abbr}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="flex-grow">
                  <label className="block text-sm font-medium mb-1">Voice</label>
                  <Select
                    value={newRequest.voice_by}
                    onChange={(e) => setNewRequest(prev => ({ ...prev, voice_by: e.target.value }))}
                    fullWidth
                  >
                    <MenuItem value="morgan_freeman">Morgan Freeman</MenuItem>
                    <MenuItem value="female_ref">Female</MenuItem>
                  </Select>
                </div>

                <div className="flex-grow">
                  <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Target Duration
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>Selected Value:</Typography>
                    <TextField
                      size="small"
                      value={newRequest.target_video_duration}
                      onChange={(e) => setNewRequest(prev => ({ 
                        ...prev, 
                        target_video_duration: parseInt(e.target.value) || 0 
                      }))}
                      type="number"
                      sx={{ width: '100px' }}
                      inputProps={{ min: 20, max: 300 }}
                    />
                  </Box>
                  <Box sx={{ px: 2, pb: 4, pt: 1 }}>
                    <Slider
                      value={newRequest.target_video_duration}
                      onChange={(_, value) => setNewRequest(prev => ({ 
                        ...prev, 
                        target_video_duration: value 
                      }))}
                      step={null}
                      marks={[
                        { 
                          value: 20, 
                          label: '20s'
                        },
                        { 
                          value: 30, 
                          label: <div style={{ marginTop: '-40px' }}>30s</div> 
                        },
                        { 
                          value: 60, 
                          label: '1 min'
                        },
                        { 
                          value: 120, 
                          label: '2 min'
                        },
                        { 
                          value: 300, 
                          label: '5 min'
                        }
                      ]}
                      min={20}
                      max={300}
                    />
                  </Box>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Prompt</label>
                <TextField
                  value={newRequest.input_prompt}
                  onChange={(e) => setNewRequest(prev => ({ ...prev, input_prompt: e.target.value }))}
                  placeholder="Enter video prompt"
                  fullWidth
                  required
                  multiline
                  rows={3}
                />
              </div>

              <Button 
                onClick={() => setShowAdvanced(!showAdvanced)}
                variant="text"
                className="mt-4"
              >
                {showAdvanced ? 'Hide Advanced Options' : 'Show Advanced Options'}
              </Button>

              {showAdvanced && (
                <div className="space-y-4 mt-4 p-4 bg-gray-50 rounded-md">
                  <div className="col-span-2">
                    <Box sx={{ mb: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Video Smoothness
                      </Typography>
                    </Box>
                    <Tooltip 
                      title={
                        <div>
                          <Typography variant="subtitle2">Frames Per Second (FPS)</Typography>
                          <Typography variant="body2">Video smoothness vs. processing speed</Typography>
                          <Typography variant="caption">
                            Technical: Number of frames rendered per second. Affects motion fluidity and generation time.
                          </Typography>
                        </div>
                      }
                      placement="top-start"
                      arrow
                    >
                      <div>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography gutterBottom>Video Smoothness</Typography>
                          <TextField
                            size="small"
                            value={newRequest.fps}
                            onChange={(e) => setNewRequest(prev => ({ 
                              ...prev, 
                              fps: parseInt(e.target.value) || 0 
                            }))}
                            type="number"
                            sx={{ width: '100px' }}
                            inputProps={{ min: 12, max: 30 }}
                          />
                        </Box>
                        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                          <Slider
                            value={newRequest.fps}
                            onChange={(_, value) => setNewRequest(prev => ({ ...prev, fps: value }))}
                            step={null}
                            marks={[
                              { value: 12, label: 'Faster' },
                              { value: 18, label: 'Balanced' },
                              { value: 24, label: 'Smoother' },
                              { value: 30, label: 'Maximum' }
                            ]}
                            min={12}
                            max={30}
                          />
                        </Box>
                      </div>
                    </Tooltip>
                  </div>

                  <Divider sx={{ my: 3 }} />

                  <div className="col-span-2">
                    <Box sx={{ mb: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Creative Freedom
                      </Typography>
                    </Box>
                    <Tooltip 
                      title={
                        <div>
                          <Typography variant="subtitle2">Augmentation Level</Typography>
                          <Typography variant="body2">Controls output variation and creativity</Typography>
                          <Typography variant="caption">
                            Technical: Stochastic variation coefficient in the generation process.
                            • 0.01: Minimal variation
                            • 0.02-0.03: Standard production
                            • 0.05: Creative variation
                            • 0.08: Maximum artistic freedom
                          </Typography>
                        </div>
                      }
                      placement="top-start"
                      arrow
                    >
                      <div>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography gutterBottom>Creative Freedom</Typography>
                          <TextField
                            size="small"
                            value={newRequest.augmentation_level.toFixed(2)}
                            onChange={(e) => setNewRequest(prev => ({ 
                              ...prev, 
                              augmentation_level: parseFloat(e.target.value) || 0 
                            }))}
                            type="number"
                            sx={{ width: '100px' }}
                            inputProps={{ step: 0.01, min: 0.01, max: 0.08 }}
                          />
                        </Box>
                        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                          <Slider
                            value={newRequest.augmentation_level * 100}
                            onChange={(_, value) => setNewRequest(prev => ({ 
                              ...prev, 
                              augmentation_level: value / 100 
                            }))}
                            step={null}
                            marks={[
                              { value: 1, label: 'Consistent' },
                              { value: 2, label: 'Balanced' },
                              { value: 5, label: 'Creative' },
                              { value: 8, label: 'Very Creative' }
                            ]}
                            min={1}
                            max={8}
                          />
                        </Box>
                      </div>
                    </Tooltip>
                  </div>

                  <Divider sx={{ my: 3 }} />

                  <div className="col-span-2">
                    <Box sx={{ mb: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Quality Level
                      </Typography>
                    </Box>
                    <Tooltip 
                      title={
                        <div>
                          <Typography variant="subtitle2">Sampling Steps</Typography>
                          <Typography variant="body2">Balance between generation speed and quality</Typography>
                          <Typography variant="caption">
                            Technical: Number of denoising iterations in the diffusion process.
                            • 20 steps: Rapid prototyping
                            • 30 steps: Production standard
                            • 40 steps: High-fidelity output
                            • 50 steps: Maximum detail refinement
                          </Typography>
                        </div>
                      }
                      placement="top-start"
                      arrow
                    >
                      <div>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography gutterBottom>Quality Level</Typography>
                          <TextField
                            size="small"
                            value={newRequest.cw11_sd_config.video_sampler_steps}
                            onChange={(e) => setNewRequest(prev => ({
                              ...prev,
                              cw11_sd_config: {
                                ...prev.cw11_sd_config,
                                video_sampler_steps: parseInt(e.target.value) || 0,
                                sampler_steps: (parseInt(e.target.value) || 0) + 10
                              }
                            }))}
                            type="number"
                            sx={{ width: '100px' }}
                            inputProps={{ min: 20, max: 50 }}
                          />
                        </Box>
                        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                          <Slider
                            value={newRequest.cw11_sd_config.video_sampler_steps}
                            onChange={(_, value) => setNewRequest(prev => ({
                              ...prev,
                              cw11_sd_config: {
                                ...prev.cw11_sd_config,
                                video_sampler_steps: value,
                                sampler_steps: value + 10
                              }
                            }))}
                            step={null}
                            marks={[
                              { value: 20, label: 'Draft' },
                              { value: 30, label: 'Standard' },
                              { value: 40, label: 'High' },
                              { value: 50, label: 'Maximum' }
                            ]}
                            min={20}
                            max={50}
                          />
                        </Box>
                      </div>
                    </Tooltip>
                  </div>

                  <Divider sx={{ my: 3 }} />

                  <div className="col-span-2">
                    <Box sx={{ mb: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Prompt Adherence
                      </Typography>
                    </Box>
                    <Tooltip 
                      title={
                        <div>
                          <Typography variant="subtitle2">Classifier-Free Guidance Scale (CFG)</Typography>
                          <Typography variant="body2">Prompt adherence vs. creative interpretation</Typography>
                          <Typography variant="caption">
                            Technical: Strength of the classifier-free guidance in the diffusion process.
                            • 3.0: Loose interpretation
                            • 3.5: Balanced adherence
                            • 5.0: Strict following
                            • 7.0: Maximum prompt compliance
                          </Typography>
                        </div>
                      }
                      placement="top-start"
                      arrow
                    >
                      <div>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography gutterBottom>Prompt Adherence</Typography>
                          <TextField
                            size="small"
                            value={newRequest.cw11_sd_config.video_sampler_cfg}
                            onChange={(e) => setNewRequest(prev => ({
                              ...prev,
                              cw11_sd_config: {
                                ...prev.cw11_sd_config,
                                video_sampler_cfg: parseFloat(e.target.value) || 0,
                                sampler_cfg: parseFloat(e.target.value) * 2 || 0
                              }
                            }))}
                            type="number"
                            sx={{ width: '100px' }}
                            inputProps={{ step: 0.01, min: 3, max: 7 }}
                          />
                        </Box>
                        <Box sx={{ px: 2, pb: 2, pt: 1 }}>
                          <Slider
                            value={newRequest.cw11_sd_config.video_sampler_cfg}
                            onChange={(_, value) => setNewRequest(prev => ({
                              ...prev,
                              cw11_sd_config: {
                                ...prev.cw11_sd_config,
                                video_sampler_cfg: value,
                                sampler_cfg: value * 2
                              }
                            }))}
                            step={null}
                            marks={[
                              { value: 3, label: 'More Creative' },
                              { value: 3.5, label: 'Balanced' },
                              { value: 5, label: 'Precise' },
                              { value: 7, label: 'Strict' }
                            ]}
                            min={3}
                            max={7}
                          />
                        </Box>
                      </div>
                    </Tooltip>
                  </div>

                  <Divider sx={{ my: 3 }} />

                  <div className="col-span-2">
                    <Box sx={{ mb: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Generation Algorithm
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                      <Typography>Selected Value:</Typography>
                      <TextField
                        size="small"
                        value={newRequest.cw11_sd_config.video_sampler_sampler_name}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ width: '200px' }}
                      />
                    </Box>
                    <Select
                      value={newRequest.cw11_sd_config.video_sampler_sampler_name}
                      onChange={(e) => setNewRequest(prev => ({
                        ...prev,
                        cw11_sd_config: {
                          ...prev.cw11_sd_config,
                          video_sampler_sampler_name: e.target.value,
                          sampler_name: e.target.value
                        }
                      }))}
                      sx={{ minHeight: '100px' }}
                    >
                      <MenuItem value="euler_ancestral">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <div>
                            <Typography variant="body1">Fast Generation</Typography>
                            <Typography variant="caption" color="text.secondary">
                              Produces slightly softer textures, good color blending, faster rendering.
                              Best for: Motion-heavy videos, rapid prototyping, simpler scenes.
                            </Typography>
                          </div>
                          <Chip 
                            size="small" 
                            label="⚡ Fast" 
                            color="primary" 
                            variant="outlined"
                          />
                        </Box>
                      </MenuItem>
                      <MenuItem value="dpmpp_2s_ancestral">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <div>
                            <Typography variant="body1">Detailed Generation</Typography>
                            <Typography variant="caption" color="text.secondary">
                              Sharper textures, better edge preservation, more accurate details.
                              Best for: Architectural scenes, text rendering, complex patterns.
                            </Typography>
                          </div>
                          <Chip 
                            size="small" 
                            label="✨ High Quality" 
                            color="secondary" 
                            variant="outlined"
                          />
                        </Box>
                      </MenuItem>
                    </Select>
                  </div>

                  <Divider sx={{ my: 3 }} />

                  <div className="col-span-2">
                    <Box sx={{ mb: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Detail Balance
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                      <Typography>Selected Value:</Typography>
                      <TextField
                        size="small"
                        value={newRequest.cw11_sd_config.video_sampler_scheduler}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ width: '200px' }}
                      />
                    </Box>
                    <Select
                      value={newRequest.cw11_sd_config.video_sampler_scheduler}
                      onChange={(e) => setNewRequest(prev => ({
                        ...prev,
                        cw11_sd_config: {
                          ...prev.cw11_sd_config,
                          video_sampler_scheduler: e.target.value,
                          sampler_scheduler: e.target.value
                        }
                      }))}
                      sx={{ minHeight: '100px' }}
                    >
                      <MenuItem value="normal">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <div>
                            <Typography variant="body1">Standard Balance</Typography>
                            <Typography variant="caption" color="text.secondary">
                              Balanced contrast, uniform detail distribution.
                              Best for: People, natural scenes, general purpose videos.
                            </Typography>
                          </div>
                          <Chip 
                            size="small" 
                            label="📊 Balanced" 
                            color="primary" 
                            variant="outlined"
                          />
                        </Box>
                      </MenuItem>
                      <MenuItem value="karras">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <div>
                            <Typography variant="body1">Enhanced Details</Typography>
                            <Typography variant="caption" color="text.secondary">
                              Higher micro-contrast, enhanced fine details, better feature separation.
                              Best for: Technical drawings, text, intricate patterns, landscapes.
                            </Typography>
                          </div>
                          <Chip 
                            size="small" 
                            label="🔍 Detailed" 
                            color="secondary" 
                            variant="outlined"
                          />
                        </Box>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              )}

              <Button type="submit" variant="contained" color="primary" fullWidth>
                Create Video
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* Video Requests list card with refresh button */}
        <Card>
          <CardHeader title="Video Requests" />
          <CardContent>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <Button
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
                onClick={handleFetchVideoRequests}
                variant="outlined"
                size="small"
              >
                {isLoading ? 'Refreshing...' : 'Refresh List'}
              </Button>
            </Box>
            <div className="rounded-md border overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th scope="col" className="w-10 px-6 py-3"></th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('video_type')}
                    >
                      Type <ArrowUpDown className="inline w-4 h-4 ml-1" />
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">
                      Prompt
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('status')}
                    >
                      Status <ArrowUpDown className="inline w-4 h-4 ml-1" />
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Est. Time</th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Actual Time</th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Progress</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Actions</th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort('created_at')}
                    >
                      Created <ArrowUpDown className="inline w-4 h-4 ml-1" />
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Started</th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Completed</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {videos.map((video) => (
                    <tr key={video.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                      </td>
                      <td className="px-6 py-4">
                        <Tooltip title={VIDEO_TYPES.find(t => t.id === video.video_type)?.name || video.video_type}>
                          <Chip label={video.video_type} variant="outlined" />
                        </Tooltip>
                      </td>
                      <td className="px-6 py-4">
                        <p className="truncate max-w-xs" title={video.input_prompt}>{video.input_prompt}</p>
                      </td>
                      <td className="px-6 py-4">
                        <Chip
                          className={STATUS_BADGES[video.status].color}
                          label={video.status}
                          icon={React.createElement(STATUS_BADGES[video.status].icon, {
                            className: 'w-4 h-4 mr-1 inline'
                          })}
                        />
                      </td>
                      <td className="px-6 py-4 text-sm">{formatDateTime(video.created_at)}</td>
                      <td className="px-6 py-4 text-sm">{video.started_at ? formatDateTime(video.started_at) : '-'}</td>
                      <td className="px-6 py-4 text-sm">{video.completed_at ? formatDateTime(video.completed_at) : '-'}</td>
                      <td className="px-6 py-4 text-sm">{video.estimated_duration}</td>
                      <td className="px-6 py-4 text-sm">{video.actual_duration || '-'}</td>
                      <td className="px-6 py-4">
                        <div className="w-full rounded-full h-2.5">
                          <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${calculateProgress(video)}%` }}
                          ></div>
                        </div>
                        <span className="text-xs mt-1">
                          {calculateProgress(video)}%
                        </span>
                      </td>
                      <td className="px-6 py-4 text-right text-sm font-medium">
                        <div className="flex justify-end gap-2">
                          {video.status === 'pending' && (
                            <Button size="small" variant="outlined">
                              <Play className="w-4 h-4" />
                            </Button>
                          )}
                          {video.status === 'processing' && (
                            <Button size="small" variant="outlined" className="text-red-600">
                              <Pause className="w-4 h-4" />
                            </Button>
                          )}
                          {video.status === 'completed' && (
                            <Button size="small" variant="outlined" onClick={() => { setPreviewURL(video.preview_url ? video.preview_url : null) }}>
                              <Play className="w-4 h-4" />
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
        
        <Dialog open={previewURL != null}>
          <DialogTitle>Video Preview</DialogTitle>
          <DialogContent>
            <div className="aspect-video rounded-lg overflow-hidden">
              <img
                src={previewURL}
                alt="Video preview"
                className="w-full h-full object-cover"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setPreviewURL(null) }}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setNotification(prev => ({ ...prev, open: false }))} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successPopup.open}
        autoHideDuration={6000}
        onClose={() => setSuccessPopup(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          icon={<CheckCircleOutlineIcon fontSize="inherit" />}
          severity="success"
          variant="filled"
          sx={{
            width: '100%',
            maxWidth: 400,
            boxShadow: 3,
            '& .MuiAlert-message': {
              width: '100%'
            }
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
              {successPopup.message}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.9 }}>
              Session ID: {successPopup.sessionId}
            </Typography>
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
};

export default VideoRequestManager;