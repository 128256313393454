import React, { useState } from 'react';
import './Viewers.css';
import processConfig from '../../config/processIndex.json';
import { Box, CircularProgress, Button } from '@mui/material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import { generateAndSaveTargetGroupImage } from '../../utils/processUtils';

const Glow02ProcessorScene = ({ data, onComplete, sessionId }) => {
  const [expandedScenes, setExpandedScenes] = useState({});
  const [sceneImages, setSceneImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [groupImages, setGroupImages] = useState({});
  const [loadingGroupImages, setLoadingGroupImages] = useState({});
  const process = processConfig.processes.find(p => p.id === 'glow_02');

  React.useEffect(() => {
    if (data) {
      // Add useEffect for target groups
      Object.entries(data).forEach(([groupId, group]) => {
        if (group.audience && !groupImages[groupId]) {
          generateImageForGroup(group, groupId);
        }
        // Auto-generate images when scenes are available
        group.scenes.forEach(scene => {
          if (scene.scene_visuals_extensive && !sceneImages[scene.scene_number]) {
            generateImageForScene(scene);
          }
        });
      });
    }
  }, [data]);

  const generateImageForScene = async (scene) => {
    if (!scene.scene_visuals_extensive || sceneImages[scene.scene_number]) return;
    
    const sceneName = `scene_${scene.scene_number}_${scene.scene_title.toLowerCase().replace(/\s+/g, '_')}`;
    setLoadingImages(prev => ({ ...prev, [scene.scene_number]: true }));
    
    try {
      // console.log('Checking cache for scene:', sceneName);
      const imagePaths = await generateAndSaveTargetGroupImage(
        scene.scene_visuals_extensive,
        `${sessionId}/${sceneName}`
      );
      
      setSceneImages(prev => ({
        ...prev,
        [scene.scene_number]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate image:', error);
    } finally {
      setLoadingImages(prev => ({ ...prev, [scene.scene_number]: false }));
    }
  };

  const generateImageForGroup = async (group, groupId) => {
    if (!group.audience || groupImages[groupId]) return;
    
    const targetGroupName = group.audience.toLowerCase().replace(/\s+/g, '_');
    setLoadingGroupImages(prev => ({ ...prev, [groupId]: true }));
    
    try {
      // console.log('Checking cache for group:', targetGroupName);
      const imagePaths = await generateAndSaveTargetGroupImage(
        `Professional video scene for ${group.audience} with ${group.video_base.style} style and ${group.video_base.color_scheme} color scheme`,
        `${sessionId}/${targetGroupName}`
      );
      
      setGroupImages(prev => ({
        ...prev,
        [groupId]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate group image:', error);
    } finally {
      setLoadingGroupImages(prev => ({ ...prev, [groupId]: false }));
    }
  };

  const toggleScene = (sceneId) => {
    setExpandedScenes(prev => ({
      ...prev,
      [sceneId]: !prev[sceneId]
    }));
  };

  const renderScene = (scene) => {
    const isExpanded = expandedScenes[scene.scene_number];

    return (
      <div key={scene.scene_number} className="target-group">
        <div className="target-group-header">
          <h3 onClick={() => toggleScene(scene.scene_number)}>
            <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
            Scene {scene.scene_number}: {scene.scene_title}
          </h3>
          
          {/* Show small image when collapsed */}
          {!isExpanded && (
            <div className="group-image">
              {loadingImages[scene.scene_number] ? (
                <CircularProgress size={24} />
              ) : sceneImages[scene.scene_number] ? (
                <img 
                  src={sceneImages[scene.scene_number]} 
                  alt={`Visual for Scene ${scene.scene_number}`}
                  style={{ 
                    maxWidth: '215px',
                    maxHeight: '215px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => toggleScene(scene.scene_number)}
                />
              ) : null}
            </div>
          )}
        </div>
        
        {isExpanded && (
          <div className="group-content">
            {/* Show full-size image when expanded */}
            <div className="group-image">
              {loadingImages[scene.scene_number] ? (
                <CircularProgress size={40} />
              ) : sceneImages[scene.scene_number] ? (
                <img 
                  src={sceneImages[scene.scene_number]} 
                  alt={`Visual for Scene ${scene.scene_number}`}
                  style={{ 
                    maxWidth: '512px',
                    maxHeight: '512px',
                    width: '100%',
                    objectFit: 'contain',
                    borderRadius: '8px'
                  }}
                />
              ) : null}
            </div>

            <div className="problem-solution">
              <div className="section">
                <h4>Purpose & Emotion:</h4>
                <p><strong>Purpose:</strong> {scene.purpose}</p>
                <p><strong>Emotion:</strong> {scene.emotion}</p>
              </div>
              
              <div className="section">
                <h4>Visual & Audio:</h4>
                <p><strong>Visuals Basic:</strong> {scene.scene_visuals_basics}</p>
                <p><strong>Visuals Extensive:</strong> {scene.scene_visuals_extensive}</p>
                <p><strong>Background Audio:</strong> {scene.scene_audio_background}</p>
                <p><strong>Narration:</strong> {scene.audio_narration}</p>
              </div>
            </div>
            
            <div className="section">
              <h4>Success Metric:</h4>
              <p>{scene.success_metric}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTargetGroup = (groupData, groupId) => {
    const isExpanded = expandedScenes[`group_${groupId}`];
    
    return (
      <div key={groupId} className="target-group">
        <div className="target-group-header">
          <h3 onClick={() => toggleScene(`group_${groupId}`)}>
            <span className={`collapse-indicator ${isExpanded ? 'expanded' : ''}`}>▶</span>
            {groupData.audience}
          </h3>
          
          {/* Show small image when collapsed */}
          {!isExpanded && (
            <div className="group-image">
              {loadingGroupImages[groupId] ? (
                <CircularProgress size={24} />
              ) : groupImages[groupId] ? (
                <img 
                  src={groupImages[groupId]} 
                  alt={`Visual for ${groupData.audience}`}
                  style={{ 
                    maxWidth: '215px',
                    maxHeight: '215px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => toggleScene(`group_${groupId}`)}
                />
              ) : null}
            </div>
          )}
        </div>
        
        {isExpanded && (
          <div className="group-content">
            {/* Show full-size image when expanded */}
            <div className="group-image">
              {loadingGroupImages[groupId] ? (
                <CircularProgress size={40} />
              ) : groupImages[groupId] ? (
                <img 
                  src={groupImages[groupId]} 
                  alt={`Visual for ${groupData.audience}`}
                  style={{ 
                    maxWidth: '512px',
                    maxHeight: '512px',
                    width: '100%',
                    objectFit: 'contain',
                    borderRadius: '8px'
                  }}
                />
              ) : null}
            </div>

            {/* Video Base Settings */}
            <div className="section">
              <h4>Video Base Settings:</h4>
              <p><strong>Style:</strong> {groupData.video_base.style}</p>
              <p><strong>Color Scheme:</strong> {groupData.video_base.color_scheme}</p>
              <p><strong>Duration:</strong> {groupData.video_base.target_duration_in_sec} seconds</p>
              
              <div className="audio-base">
                <h4>Audio Base:</h4>
                <p><strong>Timbre:</strong> {groupData.video_base.audio_base.timbre}</p>
                <p><strong>Tempo:</strong> {groupData.video_base.audio_base.tempo}</p>
                <p><strong>Core Rhythm:</strong> {groupData.video_base.audio_base.core_rhythm}</p>
                <p><strong>Harmonic Base:</strong> {groupData.video_base.audio_base.harmonic_base}</p>
              </div>
            </div>

            {/* Scenes */}
            <div className="scenes-container">
              <h4>Scenes:</h4>
              {groupData.scenes.map(scene => renderScene(scene))}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <Box>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_02"
        sessionId={sessionId}
        showRerun={!!process.endpoints.run}
      />
      <div className="target-groups-container">
        {Object.entries(data).map(([groupId, group]) => 
          renderTargetGroup(group, groupId)
        )}
      </div>
      <ViewFooter
        isDisabled={!sessionId}
        onComplete={onComplete}
        buttonText={process.footer.continueText}
      />
    </Box>
  );
};

export default Glow02ProcessorScene;
