import { configureStore } from '@reduxjs/toolkit'
import companiesReducer from '../components/company/companySlide'
import authenticateReducer from '../auth/authenticateSlide'
import sessionReducer from '../components/session/sessionSlide'
import glowStepsReducer from './glowStepsSlice'
import notificationReducer from '../components/notification/notificationSlide'

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    authenticate: authenticateReducer,
    session: sessionReducer,
    notification: notificationReducer,
    glowSteps: glowStepsReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
})