import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './Viewers.css';
import './glow_03_processor_script.css'
import processConfig from '../../config/processIndex.json';
import { Box, CircularProgress, Button, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import {Close} from '@mui/icons-material';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import { ProcessChainManager } from '../../utils/processChain';
import { generateAndSaveTargetGroupImage } from '../../utils/processUtils';
import ActionBar from './glow_04_action_bar';
import { updateGlowData } from '../../components/session/sessionSlide';
import { SAMPLE_GLOW_01, SAMPLE_GROUP_IMAGES, SAMPLE_SCENE_IMAGES, SAMPLE_FRAME_IMAGES } from '../../data/sampleGlowData';

const Glow04ProcessorIcicle = ({ data, onComplete, sessionId }) => {
  const dispatch = useDispatch();
  const process = processConfig.processes.find(p => p.id === 'glow_04');

  // Data comes in directly, not wrapped
  const hasRealData = data && Object.entries(data).length ? true : false ;  
  const [displayData, setDisplayData] = useState(() => {
    const initialData = hasRealData ? data : SAMPLE_GLOW_01;
    
    return Array.isArray(initialData) ? initialData : [initialData];
  });

  const [isDemo, setIsDemo] = useState(!hasRealData);

  const [isLoading, setIsLoading] = useState(false);
  const [processedData, setProcessedData] = useState(data);

  const [hoverScene, setHoverScene] = useState()
  const [hoverFrame, setHoverFrame] = useState()
  const [hoverRelatedNode, setHoverRelatedNode] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedScene, setSelectedScene] = useState()
  const [selectedFrame, setSelectedFrame] = useState()

  const [groupImages, setGroupImages] = useState({});
  const [loadingGroupImages, setLoadingGroupImages] = useState({});
  const [frameImages, setFrameImages] = useState({});
  const [loadingFrameImages, setLoadingFrameImages] = useState({});
  const [sceneImages, setSceneImages] = useState({});
  const [loadingSceneImages, setLoadingSceneImages] = useState({});
  const [sceneCount, setSceneCount] = useState({});
  const [frameCount, setFrameCount] = useState({});
  const selectedWidth = 70

  const [dialogData, setDialogData] = useState({title: '', subtitle: '', img: ''});

  React.useEffect(() => {
    // Generate key for scene and frame combine with group number 
    displayData.forEach(group => {
      setSceneCount(prevCount => ({ ...prevCount, [group.id]: group.scenes?.length || 0 }));
      let nbFrame = 0;
      group.scenes?.forEach(scene => {
        nbFrame += scene.shots?.length || 0;
      });
      setFrameCount(prevCount => ({ ...prevCount, [group.id]: nbFrame }));
    });
    
    if (displayData && !isDemo) {
      displayData.forEach(group => {
        if (group.audience && !groupImages[group.id]) {
          generateImageForGroup(group, group.id);
        }
        
        group.scenes?.forEach(scene => {
          const sceneKey = `${group.id}_${scene.sc_id}`;
          if (scene.sc_visuals_extensive && !sceneImages[sceneKey]) {
            generateImageForScene(scene, sceneKey);
          }
          scene.shots?.forEach(frame => {
            const frameKey = `${group.id}_${scene.sc_id}_${frame.shot_number}`;
            if (frame.visu_main_subject && !frameImages[frameKey]) {
              generateImageForFrame(frame, frameKey);
            }
          });
        });
      });
    }
    console.log("Updated data", displayData);
  }, [displayData]);

  React.useEffect(() => {
    if (isDemo) {
      setGroupImages(SAMPLE_GROUP_IMAGES);
      setSceneImages(SAMPLE_SCENE_IMAGES); 
      setFrameImages(SAMPLE_FRAME_IMAGES);
    } else {
      // Reset all images when switching from demo to non-demo
      setGroupImages({});
      setSceneImages({});
      setFrameImages({});
    }
  }, [isDemo]);
  
  const updateDisplayData = async (groupId, data, glow_id) => {
    const updatedData = displayData.map(group => {
      if (group.id == groupId && typeof data === 'object' && data !== null) {
        const updatedGroup = { ...group };
        Object.entries(data).forEach(([key, value]) => {
          if (value.scenes) {
            if(!updatedGroup.video_id && value.video_id) updatedGroup.video_id = value.video_id;
            if (glow_id === 'glow_02') {
              updatedGroup.scenes = value.scenes.map(scene => {
                return { 
                  ...scene, 
                  key: `${group.id}_${scene.sc_id}`,
                  shots: [...(scene.shots || [])] 
                };
              });
            } else if (glow_id === 'glow_03') {
              updatedGroup.scenes = group.scenes.map(existingScene => {
                const scene = value.scenes.find(s => s.sc_id == existingScene.sc_id);
                if (scene) {
                  return {
                    ...existingScene,
                    shots: scene.shots.map(shot => ({
                      ...shot,
                      key: `${group.id}_${existingScene.sc_id}_${shot.shot_number}`
                    }))
                  };
                }
                return existingScene;
              });
            }
          }
        });
        return updatedGroup;
      }
      return group;
    });
    
    setDisplayData(updatedData);
    setIsDemo(false)
    // Dispatch updateGlowData action after updating displayData
    dispatch(updateGlowData({ glowId: 'glow_04', data: { 'glow_04_response' : updatedData } }));
  }

  const generateImageForGroup = async (group, groupId) => {
    if (!group.audience || groupImages[groupId]) return;
    
    setLoadingGroupImages(prev => ({ ...prev, [groupId]: true }));
    const targetGroupName = group.audience.toLowerCase().replace(/\s+/g, '_');
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        group.visual_description,
        `${sessionId}/${targetGroupName}`
      );
      
      setGroupImages(prev => ({
        ...prev,
        [groupId]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate group image:', error);
    } finally {
      setLoadingGroupImages(prev => ({ ...prev, [groupId]: false }));
    }
  };

  const generateImageForFrame = async (frame, frameKey) => {
    if (!frame.visu_main_subject || frameImages[frameKey]) return;
    
    setLoadingFrameImages(prev => ({ ...prev, [frameKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        frame.visu_main_subject,
        `${sessionId}/frame_${frameKey}`
      );
      
      setFrameImages(prev => ({
        ...prev,
        [frameKey]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate frame image:', error);
    } finally {
      setLoadingFrameImages(prev => ({ ...prev, [frameKey]: false }));
    }
  };

  const generateImageForScene = async (scene, sceneKey) => {
    const sceneName = `scene_${scene.sc_id}_${scene.sc_title.toLowerCase().replace(/\s+/g, '_')}`;
    
    if (!scene.sc_visuals_extensive || sceneImages[sceneKey]) return;
    
    setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: true }));
    try {
      const imagePaths = await generateAndSaveTargetGroupImage(
        scene.sc_visuals_extensive,
        `${sessionId}/${sceneName}`
      );
      setSceneImages(prev => ({
        ...prev,
        [sceneKey]: imagePaths.s3Path
      }));
    } catch (error) {
      console.error('Failed to generate scene image:', error);
    } finally {
      setLoadingSceneImages(prev => ({ ...prev, [sceneKey]: false }));
    }
  };

  const toggleGroup = (groupId) => {
  };

  const toggleScene = (sceneKey) => {
    if(selectedScene === sceneKey) setSelectedScene()
    else setSelectedScene(sceneKey); 
  };

  const toggleFrame = (frameKey) => {
    if(selectedScene && selectedFrame === frameKey) setSelectedFrame()
    else setSelectedFrame(frameKey);
  };

  const renderFrame = (frame, groupId, scene, selectedSceneData) => {
    const frameKey = frame.key;
    
    const nbFrame = scene?.shots?.length || 0;
    // const isExpanded = expandedFrames[frameKey];
    const isSelected = frameKey === selectedFrame;
    const otherWidth = (100 - selectedWidth) /  Math.max(1, sceneCount[groupId] - 1)

    const sceneWidth = selectedScene 
      ? (scene.key === selectedScene ? selectedWidth : otherWidth) 
      : nbFrame /  Math.max(1, frameCount[groupId]) * 100

    const selectedFrameData = (selectedFrame && !!scene) ? scene.shots?.find(frame => frame.key === selectedFrame) : {};

    // This block calculates the width of the frame based on the selected scene and frame states.
    // It initializes the frameWidth to 100% and adjusts it based on whether a scene is selected
    // and whether a specific frame is selected within that scene.
    const shotsLength = scene?.shots?.length || 1;
    const isCurrentSceneSelected = selectedSceneData?.key === scene?.key;
    
    let frameWidth;
    if (!selectedFrame) {
      // No frame selected - distribute width evenly within scene
      frameWidth = `${(1 / shotsLength) * (isCurrentSceneSelected ? selectedWidth : sceneWidth)}%`;
    } else if (isCurrentSceneSelected) {
      // Frame selected in current scene
      const remainingFrames = frameCount[groupId] - shotsLength;
      const neighbourWidth = (100 - selectedWidth) / remainingFrames;
      
      frameWidth = selectedFrame === frameKey && selectedFrameData
        ? `${selectedWidth - neighbourWidth * (shotsLength - 1)}%` 
        : `${neighbourWidth}%`;
    } else {
      // Frame in non-selected scene
      frameWidth = `${(1 / shotsLength) * sceneWidth}%`;
    }

    return (
      <div key={frameKey} className={`frame-wrapper${selectedScene && selectedScene === scene.key && selectedFrame === frameKey && selectedFrameData ? (isSelected ? ' selected' : ' ') : ''}`}
        style={{
            width: frameWidth
          }}>
        <div className={`frame-card ${(!selectedScene && hoverScene === scene.key) || (!selectedScene && hoverFrame === groupId) ? ' hover' : ''}`}>
            <div className="frame-image">
              {loadingFrameImages[frameKey] ? (
                <CircularProgress size={24} />
              ) : frameImages[frameKey] ? (
                <img 
                  src={frameImages[frameKey]} 
                  alt={`Visual for Frame ${frameKey}`}
                  onClick={(e) => {
                    if (isSelected) {
                      setDialogData({title: frame.visu_main_subject, img: frameImages[frameKey]});
                    } else {                      
                      if (selectedScene !== scene.key) {
                        toggleScene(scene.key); 
                      }
                      toggleFrame(frameKey);
                    }
                  }}
                  onMouseEnter={() => { setHoverRelatedNode({frame: groupId, scene: scene.key}) }}
                  onMouseLeave={() => { setHoverRelatedNode() }}
                />
              ) : (
                <Button 
                  onClick={(e) => {
                    e.stopPropagation();
                    generateImageForFrame(frame, frameKey);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Generate Frame Visual
                </Button>
              )}
            </div>
        </div>
        {selectedScene && selectedScene === scene.key && selectedFrame === frameKey && selectedFrameData && renderFrameDetail(selectedFrameData)}
        {selectedScene && selectedScene === scene.key && selectedFrame === frameKey && selectedFrameData && (
          <div 
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              zIndex: 10
            }}
            onClick={(e) => {
              if (selectedScene !== scene.key) {
                toggleScene(scene.key); 
              }
              toggleFrame(frameKey);
            }}>
            <IconButton size="small">
              <Close/>
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const renderFrameDetail = (frameData) => {
    return (
      <div className="frame-details">
        {frameData.primary_person && (
          <div className="frame-item">
            <strong>Primary Person:</strong>
            <p>Description: {frameData.primary_person.description}</p>
            <p>Action: {frameData.primary_person.action}</p>
            <p>Expression: {frameData.primary_person.expression}</p>
          </div>
        )}
        
        {frameData.environment && (
          <div className="frame-item">
            <strong>Environment:</strong>
            <p>Setting: {frameData.environment.setting}</p>
            <p>Background: {frameData.environment.background}</p>
            <p>Props: {frameData.environment.props.join(', ')}</p>
          </div>
        )}
        
        {frameData.technical && (
          <div className="frame-item">
            <strong>Technical:</strong>
            <p>Camera: {frameData.technical.camera}</p>
            <p>Lighting: {frameData.technical.lighting}</p>
            {frameData.technical.color_grade && (
              <p>Color Grade: {frameData.technical.color_grade}</p>
            )}
          </div>
        )}
        
        {frameData.audio && (
          <div className="frame-item">
            <strong>Audio:</strong>
            <p>Narration: {frameData.audio.narration || 'None'}</p>
            <p>Music: {frameData.audio.music}</p>
          </div>
        )}
        
        <p><strong>Shot number:</strong> {frameData.shot_number}</p>
        <div className="frame-item">
          <strong>Subject:</strong>
          <p>{frameData.visu_main_subject}</p>
        </div>
        <p><strong>Environment:</strong> {frameData.visu_environment}</p>
        <p><strong>Technical:</strong> {frameData.visu_technical}</p>
        <p><strong>Type:</strong> {frameData.visu_shot_type}</p>
        <p><strong>Subject treatment:</strong> {frameData.visu_subject_treatment}</p>
        <p><strong>Duration:</strong> {frameData.duration_shot} seconds</p>
        <p><strong>Transition:</strong> {frameData.transition}</p>
      </div>
    )
  }
  
  const renderScene = (sceneData, groupId, selectedSceneData) => {    
    const sceneKey = sceneData.key;
    const nbFrame = sceneData?.shots?.length || 0;
    // const isExpanded = expandedScenes[sceneKey];
    const isSelected = selectedScene && sceneData.key === selectedScene;
    const otherWidth = (100 - selectedWidth) /  Math.max(1, sceneCount[groupId] - 1)
    const sceneWidth = selectedScene && selectedSceneData 
        ? (isSelected ? selectedWidth : otherWidth) 
        : nbFrame ? nbFrame /  Math.max(1, frameCount[groupId]) * 100
        : 100 / sceneCount[groupId];
    
    return (
      <div key={sceneKey} className={`scene-wrapper${isSelected ? ' selected' : ''}`}
        style={{ width: `${sceneWidth}%` }}>
        <div className={`scene-card ${(!selectedScene && hoverFrame === groupId) || (!selectedScene && hoverRelatedNode?.scene === sceneKey) ? 'hover' : ''}`}>
          <div className="scene-image">
            {loadingSceneImages[sceneKey] ? (
              <CircularProgress size={24} />
            ) : sceneImages[sceneKey] ? (
              <img 
                src={sceneImages[sceneKey]} 
                alt={`Visual for Scene ${sceneKey}`}
                onClick={(e) => {
                  if (isSelected) {
                    setDialogData({title: sceneData.sc_visuals_basics, img: sceneImages[sceneKey]});
                  } else {                    
                    toggleScene(sceneKey)
                    setSelectedFrame()
                  }
                }}
                onMouseEnter={() => { setHoverScene(sceneKey); setHoverRelatedNode({ frame: groupId }); }}
                onMouseLeave={() => { setHoverScene(); setHoverRelatedNode(); }}
              />
            ) : (
              <Button 
                onClick={() => generateImageForScene(sceneData, sceneKey)}
                variant="outlined"
                size="small"
              >
                Generate Scene Visual
              </Button>
            )}
          </div>
        </div>
        {selectedScene && selectedScene === sceneData.key && renderSceneDetail(sceneData)}
        {selectedScene && selectedScene === sceneData.key && (
          <div 
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              zIndex: 10
            }}
            onClick={(e) => {
              e.stopPropagation();
              toggleScene();
              setSelectedFrame();
            }}>
            <IconButton size="small">
              <Close/>
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const renderSceneDetail = (sceneData) => {
    return (
      <div className="scene-details">
        <p><strong>Scene Length:</strong> {sceneData.sc_length}</p>
        <p><strong>Purpose:</strong> {sceneData.purpose}</p>
        <p><strong>Emotion:</strong> {sceneData.emotion}</p>
        <div className="scene-item">
          <strong>Visual Basics:</strong>
          <p>{sceneData.sc_visuals_basics}</p>
        </div>
        <div className="scene-item">
          <strong>Visual Details:</strong>
          <p>{sceneData.sc_visuals_extensive}</p>
        </div>
        <div className="scene-item">
          <strong>Background Audio:</strong>
          <p>{sceneData.sc_audio_background}</p>
        </div>
        <div className="scene-item">
          <strong>Success Metric:</strong>
          <p>{sceneData.success_metric}</p>
        </div>
      </div>
    )
  }
  const renderTargetGroup = (groupData, groupId) => {
    const selectedSceneData = selectedScene ? groupData.scenes?.find(scene => scene.key === selectedScene) : {};
    
    return (
      <div key={`group-${groupId}`} className="target-group glow_03">
        <div className={`group-content ${(!selectedScene && hoverFrame === groupId) || (!selectedScene && hoverRelatedNode?.frame === groupId) ? 'hover' : ''}`}
              onMouseEnter={() => { setHoverFrame(groupId) }}
              onMouseLeave={() => { setHoverFrame()}}>
          {sceneCount[groupId] === 0 && <ActionBar currentLevel={0} updateData={updateDisplayData} groupId={groupId} isDemo={isDemo}/>}
          <div className='group-card'>
            <div className="group-image" onClick={() => toggleGroup(groupId)}>
              {loadingGroupImages[groupId] ? (
                <CircularProgress size={24} />
              ) : groupImages[groupId] ? (
                <img 
                  src={groupImages[groupId]} 
                  alt={`Visual for ${groupData.audience}`}
                  onClick={(e) => {
                      // Open the dialog with the full image
                      setDialogData({title: groupData.audience, img: groupImages[groupId]});
                  }}
                />
              ) : (
                <Button 
                  onClick={() => generateImageForGroup(groupData, groupId)}
                  variant="outlined"
                  size="small"
                >
                  Generate Visual
                </Button>
              )}
            </div>
          </div>

          <div className="group-detail">
            <h4>Video Base Information</h4>
            <p><strong>Description:</strong> {groupData.visual_description}</p>
            <p><strong>Duration:</strong> {groupData.target_duration_in_sec} seconds</p>
            <p><strong>Message:</strong> {groupData.main_message}</p>
          </div>
          
          <div className="group-detail">
            <h4>Key points</h4>
          <ul>
            {groupData.key_points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          </div>
        </div>
        
        <div className="scene-content">
          {sceneCount[groupId] > 0 && frameCount[groupId] === 0 && <ActionBar currentLevel={1} updateData={updateDisplayData} groupId={groupId} isDemo={isDemo}/>}
          {groupData.scenes?.map(scene => renderScene(scene, groupId, selectedSceneData))}
        </div>
        
        <div className="frame-content">
          {groupData.scenes?.map(scene => scene.shots?.map(frame => renderFrame(frame, groupId, scene, selectedSceneData)))}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="process-viewer">
        <Box>
          <ViewerHeader 
            title={process.info.title}
            subtitle={process.info.description}
            processId="glow_04"
            sessionId={sessionId}
            showRerun={!!process.endpoints.run}
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '200px' 
          }}>
            <CircularProgress />
          </Box>
        </Box>
      </div>
    );
  }

  if (!displayData) {
    return <div>No data available</div>;
  }

  return (
    <div className="process-viewer">
      <Box>
        {isDemo && (
          <Box sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            color: 'rgba(255, 140, 0, 0.45)',
            fontSize: '48px',
            fontWeight: 'bold',
            pointerEvents: 'none',
            zIndex: 9999,
            width: '100%',
            textAlign: 'center',
            userSelect: 'none',
            textTransform: 'uppercase'
          }}>
            Sample (Glowtest)
          </Box>
        )}
        <ViewerHeader 
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_04"
          sessionId={sessionId}
          showRerun={!!process.endpoints.run}
        />
        { displayData.map(group => (
            renderTargetGroup(group, group.id)
          ))
        }
        <ViewFooter
          isDisabled={!sessionId} 
          onComplete={onComplete}
          buttonText={process.footer.continueText}
        />
        <Dialog 
          open={dialogData.img != ''} 
          onClose={() => setDialogData({title: '', subtitle: '', img: ''})} 
          maxWidth="sm"
        >
          <DialogTitle>
            { dialogData.title }
            <IconButton
              aria-label="close"
              onClick={() => setDialogData({title: '', subtitle: '', img: ''})}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ 
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden' // Prevent scrolling
          }}>
            <img 
              src={dialogData.img} 
              alt="Full view" 
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
};

export default Glow04ProcessorIcicle;