import React from 'react';
import TamSamSomViewer from './glow_10_viewer_tamsamsom';
import ViewerHeader from '../common/ViewerHeader';
import processConfig from '../../config/processIndex.json';
import ViewFooter from '../common/ViewFooter';
import { Box, Button } from '@mui/material';

const SAMPLE_DATA = {
  glow_10_response: {
    tam: {
      value: 5000000000,
      industry: "Automated Video Creation",
      description: "The total market for automated video production services targeting various sectors.",
      cagr: 20,
      source: { name: "Market Research Future", date: "2023/05/15" }
    },
    sam: {
      value: 1500000000,
      industry: "Digital Media Production",
      description: "The segment dedicated to automated video solutions for marketing and product validation.",
      cagr: 18,
      source: { name: "Grand View Research", date: "2023/07/22" }
    },
    som: {
      value: 300000000,
      industry: "Online Marketing Tools",
      description: "The market share that can be realistically captured by the business within the digital marketing automation space.",
      cagr: 15,
      source: { name: "Statista", date: "2023/08/10" }
    }
  }
};

const Glow10Processor = ({ data, onComplete, sessionId, isLandingPage = false, hideHeader = false }) => {
  const sampleData = {
    "glow_10_response": {
      "market_analysis": {
        "tam": {
          "value": 1500000000,
          "industry": "Sample services",
          "description": "Global market for sample services.",
          "cagr": 12,
          "source": {
            "name": "Market Research Future",
            "date": "2023/05/01"
          }
        },
        "sam": {
          "value": 300000000,
          "industry": "Sample services",
          "description": "Market focused on sample and related services.",
          "cagr": 10,
          "source": {
            "name": "IBISWorld",
            "date": "2023/03/15"
          }
        },
        "som": {
          "value": 60000000,
          "industry": "Sample services",
          "description": "Potential market share for a specialized sample platform.",
          "cagr": 8,
          "source": {
            "name": "Statista",
            "date": "2023/04/20"
          }
        }
      }
    }
  }
  const process = processConfig.processes.find(p => p.id === 'glow_10');
  
  console.log('Glow10Processor received data:', data);

  // Handle both data structures (direct and wrapped in market_analysis)
  const marketData = data?.glow_10_response || data?.market_analysis;
  const hasRealData = marketData?.tam?.value;
  
  // Create the correct data structure for the viewer
  const displayData = hasRealData ? { glow_10_response: marketData } : SAMPLE_DATA;
  const isDemo = !hasRealData;

  if (!process) {
    return <div>Process configuration not found</div>;
  }

  return (
    <Box>
      {/* Show watermark only when it's demo data AND not on landing page */}
      {isDemo && !isLandingPage && (
        <Box sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-45deg)',
          color: 'rgba(255, 140, 0, 0.45)',
          fontSize: '48px',
          fontWeight: 'bold',
          pointerEvents: 'none',
          zIndex: 9999,
          width: '100%',
          textAlign: 'center',
          userSelect: 'none',
          textTransform: 'uppercase'
        }}>
          Sample (Glowtest)
        </Box>
      )}

      {/* Show header unless explicitly hidden for landing page */}
      {!isLandingPage && !hideHeader && (
        <ViewerHeader 
          title={process.info.title}
          subtitle={process.info.description}
          processId="glow_09"
          sessionId={sessionId}
          showRerun={!!process.endpoints.run}
        />
      )}

      {/* Rest of your component */}
      <TamSamSomViewer data={displayData.glow_10_response} isLandingPage={isLandingPage} hideHeader={hideHeader} />
      
      {/* Only show ViewFooter when not on landing page */}
      {!isLandingPage && (
        <ViewFooter
          isDisabled={!sessionId} 
          onComplete={onComplete}
          buttonText={process?.footer?.continueText || "Continue"}
        />
      )}
    </Box>
  );
};

export default Glow10Processor;