import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Stack,
  styled,
  createTheme,
  ThemeProvider,
  Chip,
  Slider,
  Paper,
  Grid,
  IconButton,
  CircularProgress,
  Alert
} from '@mui/material';
import processConfig from '../../config/processIndex.json';
import ViewerHeader from '../common/ViewerHeader';
import ViewFooter from '../common/ViewFooter';
import {
  SmartToy as AIIcon,
  HighQuality as QualityIcon,
  AspectRatio as FormatIcon,
  Lock as LockIcon,
  NewReleases as ComingSoonIcon,
  VpnKey as RequestAccessIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { 
  FaYoutube, 
  FaInstagram, 
  FaTiktok, 
  FaFacebookF, 
  FaLinkedinIn, 
  FaPinterestP, 
  FaTwitter 
} from 'react-icons/fa';
import { 
  SiSnapchat,
  SiYoutubeshorts
} from 'react-icons/si';
import { 
  MdPresentToAll, 
  MdTv, 
  MdMovie, 
  MdPhotoCamera 
} from 'react-icons/md';
import axios from 'axios';
import Cookies from 'js-cookie';
import SelectionCard from './glow_61_selection_card';
import { useDispatch, useSelector } from 'react-redux';
import { updateGlowStep } from '../../redux/glowStepsSlice';

// Add debug logging for environment variables
console.log('Environment Variables:', {
  bucketName: process.env.REACT_APP_DEV_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_DEV_AWS_REGION
});

// Construct S3_BASE_URL using environment variables with fallbacks
const S3_BASE_URL = `https://${process.env.REACT_APP_DEV_AWS_BUCKET_NAME || 'chat2movie'}.s3.${process.env.REACT_APP_DEV_AWS_REGION || 'us-east-1'}.amazonaws.com`;

// Helper function to construct S3 URLs with correct path
const getS3Url = (path) => {
  // Map the correct filenames for styles
  const styleNameMap = {
    'realistic_image': 'realistic',
    'digital_illustration': 'digital',
    'vector_illustration': 'vector',
    'icon': 'icon',
    'logo_raster': 'logo'
  };

  let url;
  const cleanPath = path.replace(/^(thumbnails\/|full\/)/, '');

  if (path.startsWith('thumbnails/')) {
    if (cleanPath.includes('substyles/')) {
      // For substyles, use the direct path
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/substyles/${cleanPath.split('/').pop()}`;
    } else if (cleanPath.includes('styles/')) {
      const styleName = cleanPath.split('/').pop().replace('.webp', '');
      const mappedName = styleNameMap[styleName] || styleName;
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/styles/${mappedName}`;
    } else if (cleanPath.includes('providers/') || cleanPath.includes('models/')) {
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/thumbnails/${cleanPath}`;
    }
  } else if (path.startsWith('full/')) {
    if (cleanPath.includes('substyles/')) {
      // For substyles, use the direct path
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/substyles/${cleanPath.split('/').pop()}`;
    } else if (cleanPath.includes('styles/')) {
      const styleName = cleanPath.split('/').pop().replace('.webp', '');
      const mappedName = styleNameMap[styleName] || styleName;
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/styles/${mappedName}`;
    } else if (cleanPath.includes('providers/') || cleanPath.includes('models/')) {
      url = `${S3_BASE_URL}/target_groups/glow_61_engine_pictures/full/${cleanPath}`;
    }
  }

  // Ensure .webp extension is added if not present
  if (url && !url.endsWith('.webp')) {
    url += '.webp';
  }

  // Add console logging for debugging
  console.log('Original path:', path);
  console.log('Generated URL:', url);
  
  return url;
};

// Instead, use the direct URL
const API_BASE_URL = 'https://glowtest-dev-service-325458756816.asia-east2.run.app';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FD9800',
      light: '#FDB544',
      dark: '#D68100',
      contrastText: '#FFFFFF',
    }
  }
});

const FormatPreview = styled(Paper)(({ theme, ratio }) => ({
  width: '120px',
  height: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.primary.main}`,
  overflow: 'hidden',
  '& .inner-box': {
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
  }
}));

const formatOptions = [
  { 
    value: 0, 
    label: "1:2", 
    ratio: 0.5,
    description: "Perfect for full-screen mobile content on TikTok, Instagram Stories, and YouTube Shorts",
    platforms: ["TikTok", "Instagram Stories", "YouTube Shorts"]
  },
  { 
    value: 1, 
    label: "9:16", 
    ratio: 0.5625,
    description: "Most popular vertical format! Optimized for vertical social media content and mobile-first viewing",
    popularityNote: "Most popular vertical format for social media",
    platforms: ["Instagram Reels", "Snapchat", "Facebook Stories"]
  },
  { 
    value: 2, 
    label: "3:4", 
    ratio: 0.75,
    description: "Ideal for Instagram feed posts and Pinterest, maximizing vertical space while maintaining readability",
    platforms: ["Instagram Feed", "Pinterest", "Facebook Feed"]
  },
  { 
    value: 3, 
    label: "4:5", 
    ratio: 0.8,
    description: "Instagram's preferred vertical format, maximizing feed space while maintaining quality",
    platforms: ["Instagram Feed", "Facebook Feed"]
  },
  { 
    value: 4, 
    label: "1:1", 
    ratio: 1,
    description: "Perfect square format for social media feeds, especially Instagram and Facebook",
    platforms: ["Instagram Feed", "Facebook Feed", "LinkedIn"]
  },
  { 
    value: 5, 
    label: "5:4", 
    ratio: 1.25,
    description: "Classic photography format, great for landscape and portrait content",
    platforms: ["Photography Sites", "Professional Portfolios"]
  },
  { 
    value: 6, 
    label: "4:3", 
    ratio: 1.33,
    description: "Traditional presentation format, ideal for business content and older displays",
    platforms: ["Business Presentations", "LinkedIn", "Traditional TV"]
  },
  { 
    value: 7, 
    label: "16:9", 
    ratio: 1.77,
    description: "Most popular horizontal format! Standard widescreen format, perfect for YouTube videos and modern displays",
    popularityNote: "Most popular horizontal format for video content",
    platforms: ["YouTube", "Presentations", "Desktop Viewing"]
  },
  { 
    value: 8, 
    label: "2:1", 
    ratio: 2,
    description: "Cinematic format popular for website headers and landscape social media content",
    platforms: ["Website Headers", "Twitter Cards", "LinkedIn Banners"]
  },
  { 
    value: 9, 
    label: "21:9", 
    ratio: 2.33,
    description: "Ultra-wide cinematic format for dramatic visual storytelling",
    platforms: ["Cinema", "Premium Video Content", "Ultrawide Displays"]
  }
];

const ModelCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .model-image': {
    width: '100%',
    height: 120,
    objectFit: 'cover',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  '& .locked-overlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    bottom: '64px'
  }
}));

const aiModels = [
  {
    id: 'sd_3_5_large',
    name: 'SD 3.5 Large',
    description: 'Stable Diffusion 3.5 Large - Powerful image generation',
    image: '/path/to/sd35-image.jpg',
    available: true
  },
  {
    id: 'sd_xl',
    name: 'SD XL',
    description: 'Stable Diffusion XL - Enhanced detail and quality',
    image: '/path/to/sdxl-image.jpg',
    available: false
  },
  {
    id: 'dalle3',
    name: 'DALL·E 3',
    description: 'OpenAI\'s latest image generation model',
    image: '/path/to/dalle3-image.jpg',
    available: false
  },
  {
    id: 'sora',
    name: 'Sora',
    description: 'OpenAI\'s text-to-video generation model',
    image: '/path/to/sora-image.jpg',
    available: false
  },
  {
    id: 'kling',
    name: 'Kling',
    description: 'Advanced video generation and editing',
    image: '/path/to/kling-image.jpg',
    available: false
  }
];

const resolutions = [
  {
    id: '720p',
    name: '720p HD',
    description: 'Standard HD resolution (1280×720)',
    available: true
  },
  {
    id: '1080p',
    name: '1080p Full HD',
    description: 'Full HD resolution (1920×1080)',
    available: false
  },
  {
    id: '4k',
    name: '4K Ultra HD',
    description: '4K resolution (3840×2160)',
    available: false
  }
];

// Platform icon mapping
const PLATFORM_ICONS = {
  'YouTube': FaYoutube,
  'YouTube Shorts': SiYoutubeshorts,
  'TikTok': FaTiktok,
  'Instagram Stories': FaInstagram,
  'Instagram Feed': FaInstagram,
  'Instagram Reels': FaInstagram,
  'Snapchat': SiSnapchat,
  'Facebook Stories': FaFacebookF,
  'Facebook Feed': FaFacebookF,
  'LinkedIn': FaLinkedinIn,
  'Pinterest': FaPinterestP,
  'Twitter Cards': FaTwitter,
  'Business Presentations': MdPresentToAll,
  'Traditional TV': MdTv,
  'Cinema': MdMovie,
  'Photography Sites': MdPhotoCamera,
  'Professional Portfolios': MdPhotoCamera,
  'Desktop Viewing': MdPresentToAll,
  'Premium Video Content': MdMovie,
  'Ultrawide Displays': MdTv,
  'Website Headers': MdPresentToAll,
  'LinkedIn Banners': FaLinkedinIn
};

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-icon': {
    color: theme.palette.primary.main,
    marginLeft: '8px'
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main + '1A', // 10% opacity
  },
  transition: 'all 0.2s ease-in-out',
  cursor: 'default'
}));

const calculateDimensions = (format, resolution) => {
  const ratioOption = formatOptions[format];
  const ratio = ratioOption.ratio;
  
  let width, height;
  switch(resolution) {
    case '720p':
      // For vertical formats (ratio[0] < ratio[1]), make the height 720p
      if (parseInt(ratio[0]) < parseInt(ratio[1])) {
        height = 720;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      } else {
        // For horizontal formats, make the shorter side (height) 720p
        height = 720;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      }
      break;
    case '1080p':
      if (parseInt(ratio[0]) < parseInt(ratio[1])) {
        height = 1080;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      } else {
        height = 1080;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      }
      break;
    case '4k':
      if (parseInt(ratio[0]) < parseInt(ratio[1])) {
        height = 2160;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      } else {
        height = 2160;
        width = Math.round(height * (parseInt(ratio[0]) / parseInt(ratio[1])));
      }
      break;
    default:
      return null;
  }

  // For vertical formats (ratio[0] < ratio[1]), swap width and height
  if (parseInt(ratio[0]) < parseInt(ratio[1])) {
    [width, height] = [height, width];
  }

  return { width, height };
};

const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  color: theme.palette.text.primary
}));

// Common grid layout component for consistency
const SelectionGrid = ({ children }) => (
  <Grid 
    container 
    spacing={2}
    justifyContent="center"  // Center the cards
    sx={{
      maxWidth: '800px',    // Limit max width of grid
      margin: '0 auto',     // Center the grid itself
      padding: theme => theme.spacing(2)
    }}
  >
    {React.Children.map(children, child => (
      <Grid 
        item 
        xs={12}      // Full width on mobile
        sm={6}       // 2 cards per row on tablet
        md={4}       // 3 cards per row on desktop
        sx={{
          display: 'flex',
          justifyContent: 'center'  // Center cards within their grid cells
        }}
      >
        {child}
      </Grid>
    ))}
  </Grid>
);

// Updated provider cost data based on API pricing
const calculateProviderCostDifference = (provider, providerData) => {
  if (!providerData?.pricing?.services?.text_to_image) {
    return 0;
  }

  // For Recraft, use the raster_image_generation service
  if (provider === 'recraft') {
    const v3Cost = providerData.pricing.services.raster_image_generation.recraftv3.api_units;
    const v2Cost = providerData.pricing.services.raster_image_generation.recraftv2.api_units;
    return Math.round(((v3Cost - v2Cost) / v2Cost) * 100); // Calculate percentage difference
  }

  // For Stability, use the text_to_image service
  if (provider === 'stability') {
    const costs = Object.values(providerData.pricing.services.text_to_image)
      .map(service => service.api_units);
    const avgCost = costs.reduce((a, b) => a + b, 0) / costs.length;
    const maxCost = Math.max(...costs);
    return Math.round(((maxCost - avgCost) / avgCost) * 100);
  }

  return 0;
};

// Updated model cost data based on API pricing
const calculateModelCostDifference = (provider, model, providerData) => {
  if (!providerData?.pricing?.services) {
    return 0;
  }

  if (provider === 'recraft') {
    const baseService = providerData.pricing.services.raster_image_generation;
    const modelCost = baseService[model]?.api_units;
    const avgCost = Object.values(baseService).reduce((a, b) => a + b.api_units, 0) / 
                   Object.keys(baseService).length;
    return Math.round(((modelCost - avgCost) / avgCost) * 100);
  }

  if (provider === 'stability') {
    const services = providerData.pricing.services.text_to_image;
    const modelCost = services[model]?.api_units;
    const avgCost = Object.values(services).reduce((a, b) => a + b.api_units, 0) / 
                   Object.keys(services).length;
    return Math.round(((modelCost - avgCost) / avgCost) * 100);
  }

  return 0;
};

// Update the ProviderSelection component
const ProviderSelection = ({ config, selected, onChange }) => {
  if (!config?.providers) return null;

  const providers = Object.entries(config.providers)
    .filter(([_, data]) => data.status === 'active')
    .map(([provider, data]) => ({
      id: provider,
      name: provider.charAt(0).toUpperCase() + provider.slice(1),
      costDifference: calculateProviderCostDifference(provider, data)
    }));

  return (
    <Box>
      <SectionHeader variant="h6">
        Select Provider
      </SectionHeader>
      <SelectionGrid>
        {providers.map(({ id, name, costDifference }) => (
          <SelectionCard
            key={id}
            title={name}
            description="AI Provider"
            selected={selected === id}
            onClick={() => onChange(id)}
            thumbnailSrc={getS3Url(`thumbnails/providers/${id}.webp`)}
            fullImageSrc={getS3Url(`full/providers/${id}.webp`)}
            costComparison={{
              type: 'provider',
              value: costDifference
            }}
          />
        ))}
      </SelectionGrid>
    </Box>
  );
};

// Update the ModelSelection component
const ModelSelection = ({ config, provider, selected, onChange }) => {
  if (!config?.providers?.[provider]?.models?.available) return null;

  const providerData = config.providers[provider];
  const models = providerData.models.available
    .filter(model => providerData.models.status[model] === 'active')
    .map(model => ({
      id: model,
      name: model.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      costDifference: calculateModelCostDifference(provider, model, providerData)
    }));

  return (
    <Box>
      <SectionHeader variant="h6">
        Select Model Version
      </SectionHeader>
      <SelectionGrid>
        {models.map(({ id, name, costDifference }) => (
          <SelectionCard
            key={id}
            title={name}
            description="AI Model Version"
            selected={selected === id}
            onClick={() => onChange(id)}
            thumbnailSrc={getS3Url(`thumbnails/models/${id}.webp`)}
            fullImageSrc={getS3Url(`full/models/${id}.webp`)}
            costComparison={{
              type: 'model',
              value: costDifference
            }}
          />
        ))}
      </SelectionGrid>
    </Box>
  );
};

const StyleSelection = ({ config, provider, model, selected, onChange }) => {
  if (!config?.providers?.[provider]?.styles?.available) return null;

  const providerConfig = config.providers[provider];
  const stylesList = providerConfig.styles.available || [];
  const stylesData = providerConfig.styles.data || {};
  
  return (
    <Box>
      <SectionHeader variant="h6">
        Select Style
      </SectionHeader>
      <SelectionGrid>
        {stylesList.map((style) => {
          // Get the substyles for the current model
          const substyles = stylesData[style]?.[`${model}_substyles`];
          const substyleCount = Array.isArray(substyles) ? substyles.length : 0;
          
          return (
            <SelectionCard
              key={style}
              title={style.split('_').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ')}
              description={`${substyleCount} substyles available`}
              selected={selected === style}
              onClick={() => onChange(style)}
              thumbnailSrc={getS3Url(`thumbnails/styles/${style}.webp`)}
              fullImageSrc={getS3Url(`full/styles/${style}.webp`)}
            />
          );
        })}
      </SelectionGrid>
    </Box>
  );
};

const ResolutionSelection = ({ selected, onChange }) => (
  <Grid container spacing={2}>
    {resolutions.map((resolution) => (
      <Grid item xs={12} sm={6} md={4} key={resolution.id}>
        <SelectionCard
          title={resolution.name}
          description={resolution.description}
          selected={selected === resolution.id}
          onClick={() => onChange(resolution.id)}
          thumbnailSrc={getS3Url(`resolutions/${resolution.id}.webp`)}
          fullImageSrc={getS3Url(`resolutions/${resolution.id}.webp`)}
          disabled={!resolution.available}
        />
      </Grid>
    ))}
  </Grid>
);

const AIModelSelection = ({ selected, onChange }) => (
  <Grid container spacing={2}>
    {aiModels.map((model) => (
      <Grid item xs={12} sm={6} md={4} key={model.id}>
        <SelectionCard
          title={model.name}
          description={model.description}
          selected={selected === model.id}
          onClick={() => onChange(model.id)}
          thumbnailSrc={getS3Url(`ai-models/${model.id}.webp`)}
          fullImageSrc={getS3Url(`ai-models/${model.id}.webp`)}
          disabled={!model.available}
        />
      </Grid>
    ))}
  </Grid>
);

const AdditionalConfig = ({ config, provider, answers, onChange }) => {
  // Implementation of AdditionalConfig component
};

const SubstyleSelection = ({ config, provider, model, style, selected, onChange }) => {
  if (!config?.providers?.[provider]?.styles?.data?.[style]) return null;

  const stylesData = config.providers[provider].styles.data;
  const substyleKey = `${model}_substyles`;
  const substyles = stylesData[style]?.[substyleKey] || [];

  // If there are no substyles, don't show the selection
  if (!Array.isArray(substyles) || substyles.length === 0) {
    return null;
  }

  return (
    <Box>
      <SectionHeader variant="h6">
        Select Substyle
      </SectionHeader>
      <SelectionGrid>
        {substyles.map((substyle) => (
          <SelectionCard
            key={substyle}
            title={substyle.split('_').map(word => 
              word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')}
            description="Style Variation"
            selected={selected === substyle}
            onClick={() => onChange(substyle)}
            thumbnailSrc={getS3Url(`thumbnails/substyles/${substyle}.webp`)}
            fullImageSrc={getS3Url(`full/substyles/${substyle}.webp`)}
          />
        ))}
      </SelectionGrid>
    </Box>
  );
};

const Glow61Processor = ({ data, onComplete, showNotice }) => {
  const process = processConfig.processes.find(p => p.id === 'glow_61');

  const dispatch = useDispatch();
  const glowSteps = useSelector(state => state.glowSteps.glowSteps);

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [answers, setAnswers] = useState({
    format: 7,
    provider: '',
    model: '',
    style: '',
    substyle: '',
    size: '',
    quality: 'standard',
    artistic_level: null,
    no_text: false
  });
  
  // Simplified state for feature requests
  const [requestedFeatures, setRequestedFeatures] = useState(new Set());

  // Add state change logging
  useEffect(() => {
    console.log('Glow61: Current Redux State:', glowSteps);
  }, [glowSteps]);

  // Fetch configuration on component mount
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/api/v1/workflow/get_text_to_image_config`,
          {
            headers: { 
              Authorization: `Bearer ${Cookies.get('token')}`,
              accept: 'application/json'
            }
          }
        );
        setConfig(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  // Initialize Redux state when component mounts
  useEffect(() => {
    dispatch(updateGlowStep({
      stepId: 'glow_61',
      data: {
        status: false,
        response: {
          provider: '',
          model: '',
          style: '',
          substyle: '',
          format: '',
          resolution: ''
        }
      }
    }));
  }, [dispatch]);

  const isComplete = () => {
    const complete = answers.provider && answers.model && answers.style && answers.substyle;
    return complete;
  };

  const handleRequestAccess = (id, type) => {
    if (handleFeatureRequest) {  // Safely check if prop exists
      if (handleFeatureRequest(id, type)) {
        setRequestedFeatures(prev => new Set([...prev, id]));
      }
    } else {
      console.log('Warning: handleFeatureRequest prop not provided to Glow61Processor');  // Debug
    }
  };

  const isRequested = (id) => requestedFeatures.has(id);

  const handleComplete = () => {
    if (!answers.provider || !answers.model || !answers.style) {
      showNotice('Please complete all selections', 'error');
      return;
    }

    onComplete({
      processId: 'glow_61',
      data: {
        status: true,
        response: {
          provider: answers.provider,
          model: answers.model,
          style: answers.style,
          substyle: answers.substyle,
          format: answers.format,
          resolution: answers.resolution
        }
      }
    })
    
    dispatch(updateGlowStep({
      stepId: 'glow_61',
      data: {
        status: true,
        response: {
          provider: answers.provider,
          model: answers.model,
          style: answers.style,
          substyle: answers.substyle,
          format: answers.format,
          resolution: answers.resolution
        }
      }
    }));
    console.log('Completed glow_61 with state:', {
      provider: answers.provider,
      model: answers.model,
      style: answers.style,
      substyle: answers.substyle
    });
  };

  const handleFormatChange = (event, newValue) => {
    setAnswers(prev => ({
      ...prev,
      format: newValue
    }));
  };

  const getFormatPreview = (format) => {
    const option = formatOptions[format];
    const ratio = option.ratio;
    let width, height;
    
    if (ratio >= 1) {
      width = 160;
      height = width / ratio;
    } else {
      height = 160;
      width = height * ratio;
    }

    return { width, height, ratio: option.label };
  };

  const getFormatInfo = (format) => {
    const option = formatOptions[format];
    return {
      ...getFormatPreview(format),
      description: option.description,
      platforms: option.platforms
    };
  };

  // Add format selection to the render
  const formatInfo = getFormatInfo(answers.format);
  
  // Add handleFeatureRequest function
  const handleFeatureRequest = (feature) => {
    console.log('Feature requested:', feature);
    showNotice('This feature will be available soon!', 'info');
  };

  const handleSubstyleChange = (newSubstyle) => {
    setAnswers(prev => ({
      ...prev,
      substyle: newSubstyle
    }));
    
    // Only update Redux if we have all required fields
    if (answers.provider && answers.model && answers.style) {
      dispatch(updateGlowStep({
        stepId: 'glow_61',
        data: {
          status: true,  // Set to true only when we have all required fields
          response: {
            provider: answers.provider,
            model: answers.model,
            style: answers.style,
            substyle: newSubstyle,
            format: answers.format,
            resolution: answers.resolution
          }
        }
      }));
      console.log('Updated glow_61 state:', {
        provider: answers.provider,
        model: answers.model,
        style: answers.style,
        substyle: newSubstyle
      });
    }
  };

  const handleProviderChange = (newProvider) => {
    setAnswers(prev => ({
      ...prev,
      provider: newProvider,
      model: '',
      style: '',
      substyle: ''
    }));
    updateReduxState({
      provider: newProvider,
      model: '',
      style: '',
      substyle: ''
    });
  };

  const handleModelChange = (newModel) => {
    setAnswers(prev => ({
      ...prev,
      model: newModel,
      style: '',
      substyle: ''
    }));
    updateReduxState({
      provider: answers.provider,
      model: newModel,
      style: '',
      substyle: ''
    });
  };

  const handleStyleChange = (newStyle) => {
    setAnswers(prev => ({
      ...prev,
      style: newStyle,
      substyle: ''
    }));
    updateReduxState({
      provider: answers.provider,
      model: answers.model,
      style: newStyle,
      substyle: ''
    });
  };

  // Centralized Redux update function
  const updateReduxState = (selections) => {
    console.log('Glow61: Updating Redux with:', selections);
    
    dispatch(updateGlowStep({
      stepId: 'glow_61',
      data: {
        status: Boolean(selections.provider && selections.model && selections.style),
        response: {
          ...selections,
          format: selections.format || '',
          resolution: selections.resolution || ''
        }
      }
    }));

    // Log the state after update
    console.log('Glow61: State after update:', {
      path: 'glowSteps.glowSteps.glow_61',
      data: selections
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <ViewerHeader 
        title={process.info.title}
        subtitle={process.info.description}
        processId="glow_61"
        showRerun={!!process.endpoints.run}
      />
      <Box sx={{ p: 3, backgroundColor: 'var(--color-surface)' }}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Stack spacing={3} sx={{ maxWidth: 900, mx: 'auto'}}>
            <ProviderSelection 
              config={config}
              selected={answers.provider}
              onChange={(provider) => handleProviderChange(provider)}
            />

            {answers.provider && (
              <ModelSelection 
                config={config}
                provider={answers.provider}
                selected={answers.model}
                onChange={(model) => handleModelChange(model)}
              />
            )}

            {answers.model && (
              <StyleSelection 
                config={config}
                provider={answers.provider}
                model={answers.model}
                selected={answers.style}
                onChange={(style) => handleStyleChange(style)}
              />
            )}

            {answers.style && (
              <SubstyleSelection
                config={config}
                provider={answers.provider}
                model={answers.model}
                style={answers.style}
                selected={answers.substyle}
                onChange={handleSubstyleChange}
              />
            )}

            {answers.style && (
              <AdditionalConfig 
                config={config}
                provider={answers.provider}
                answers={answers}
                onChange={setAnswers}
              />
            )}

            {answers.substyle && (
              <Card elevation={0} sx={{ borderRadius: 2, backgroundColor: 'var(--card-bg)' }}>
                <CardHeader 
                  title="Choose Format"
                  sx={{ 
                    '& .MuiCardHeader-title': {
                      fontSize: '1.1rem',
                      fontWeight: 500
                    }
                  }}
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={2} alignItems="center">
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 2,
                          justifyContent: 'center'
                        }}>
                          <IconButton 
                            onClick={() => handleFormatChange(null, Math.max(0, answers.format - 1))}
                            disabled={answers.format === 0}
                            sx={{ 
                              color: 'primary.main',
                              '&.Mui-disabled': {
                                color: 'action.disabled'
                              }
                            }}
                          >
                            <ChevronLeftIcon />
                          </IconButton>
                          
                          <FormatPreview>
                            <Box 
                              className="inner-box"
                              sx={{
                                width: getFormatPreview(answers.format).width * 0.75,
                                height: getFormatPreview(answers.format).height * 0.75,
                              }}
                            >
                              {getFormatPreview(answers.format).ratio}
                            </Box>
                          </FormatPreview>
                          
                          <IconButton 
                            onClick={() => handleFormatChange(null, Math.min(9, answers.format + 1))}
                            disabled={answers.format === 9}
                            sx={{ 
                              color: 'primary.main',
                              '&.Mui-disabled': {
                                color: 'action.disabled'
                              }
                            }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Box>
                      </Stack>
                    </Grid>
                    
                    <Grid item xs={12} md={8}>
                      <Stack spacing={3}>
                        <Box sx={{ width: '100%', px: 2 }}>
                          <Slider
                            value={answers.format}
                            onChange={handleFormatChange}
                            step={1}
                            marks={formatOptions.map(option => ({
                              value: option.value,
                              label: option.label
                            }))}
                            min={0}
                            max={9}
                            sx={{
                              '& .MuiSlider-mark': {
                                height: '10px'
                              },
                              '& .MuiSlider-markLabel': {
                                fontSize: '0.75rem'
                              }
                            }}
                          />
                        </Box>
                        
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography variant="subtitle1" color="primary" gutterBottom>
                            {formatOptions[answers.format].label} Format
                          </Typography>
                          {formatOptions[answers.format].popularityNote && (
                            <Typography 
                              variant="subtitle2" 
                              sx={{ 
                                color: 'success.main',
                                fontWeight: 'bold',
                                mb: 1
                              }}
                            >
                              ★ {formatOptions[answers.format].popularityNote} ★
                            </Typography>
                          )}
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            {formatOptions[answers.format].description}
                          </Typography>
                          
                          <Stack 
                            direction="row" 
                            spacing={1} 
                            justifyContent="center" 
                            flexWrap="wrap" 
                            sx={{ gap: 1, mt: 2 }}
                          >
                            {formatOptions[answers.format].platforms.map((platform, index) => {
                              const IconComponent = PLATFORM_ICONS[platform];
                              return (
                                <StyledChip 
                                  key={index} 
                                  icon={IconComponent && <IconComponent />}
                                  label={platform}
                                  size="small" 
                                  color="primary" 
                                  variant="outlined"
                                />
                              );
                            })}
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Stack>
        )}
      </Box>
      <ViewFooter
        isDisabled={!isComplete()} 
        showButton={true}
        onComplete={handleComplete}
        buttonText="Continue to Video Type"
      />
    </ThemeProvider>
  );
};

export default Glow61Processor;